import { Tab } from "@headlessui/react";
import StayCard from "components/StayCard/StayCard";
import { Fragment, useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import { firestore } from "data/firebase";
import { collection } from "@firebase/firestore";
import {
  DocumentData,
  QueryDocumentSnapshot,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { BookingsModel } from "models/BookingsModel";
import { useApp } from "data/app-provider";
import { cancelBookingWithId } from "utils/apiUtil";

const AccountSavelists = () => {
  const { user, fleetwireToken } = useApp();
  let [categories] = useState(["Upcomming", "Previous", "Cancelled"]);
  let [upcoming, setUpcoming] = useState<BookingsModel[]>([]);
  let [previous, setPrevious] = useState<BookingsModel[]>([]);
  let [cancelled, setCancelled] = useState<BookingsModel[]>([]);
  useEffect(() => {
    fetchBookings();
  }, [user]);

  const fetchBookings = async () => {
    if (user == null) return;
    const bookingsRef = collection(firestore, "bookings");
    const q = query(bookingsRef, where("email", "==", user?.email));
    const querySnapshot = await getDocs(q);
    const bookings = querySnapshot.docs.map(
      (doc: QueryDocumentSnapshot<DocumentData>) => {
        return { ...doc.data(), id: doc.id } as BookingsModel;
      }
    );

    setUpcoming(
      bookings.filter(
        (booking) =>
          new Date(booking.end_date_time) > new Date() &&
          booking.booking_status !== "Cancelled"
      )
    );

    setPrevious(
      bookings.filter(
        (booking) =>
          new Date(booking.end_date_time) <= new Date() &&
          booking.booking_status !== "Cancelled"
      )
    );

    setCancelled(
      bookings.filter((booking) => booking.booking_status == "Cancelled")
    );

    setUpcoming((prevUpcoming) => [
      ...prevUpcoming.sort(
        (a, b) =>
          new Date(b.start_date_time).getTime() -
          new Date(a.start_date_time).getTime()
      ),
    ]);

    setPrevious((prevPrevious) => [
      ...prevPrevious.sort(
        (a, b) =>
          new Date(b.start_date_time).getTime() -
          new Date(a.start_date_time).getTime()
      ),
    ]);
  };

  const cancelBooking = async (bookingId: string) => {
    await cancelBookingWithId(bookingId, fleetwireToken!);
    const docRef = doc(firestore, "bookings", bookingId); // Reference to the specific document
    await updateDoc(docRef, { booking_status: "Cancelled" }); // Update only the bookingStatus field
    await fetchBookings();
  };

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">Account Bookings</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-primary-200 text-white"
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="mt-8">
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {upcoming
                    .filter((_, i) => i < 8)
                    .map((stay) => (
                      <StayCard
                        key={stay.id}
                        data={stay}
                        isUpcoming={true}
                        cancelFunction={cancelBooking}
                      />
                    ))}
                </div>
              </Tab.Panel>
              <Tab.Panel className="mt-8">
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {previous
                    .filter((_, i) => i < 8)
                    .map((stay) => (
                      <StayCard key={stay.id} data={stay} />
                    ))}
                </div>
              </Tab.Panel>
              <Tab.Panel className="mt-8">
                <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {cancelled
                    .filter((_, i) => i < 8)
                    .map((stay) => (
                      <StayCard key={stay.id} data={stay} />
                    ))}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountSavelists;
