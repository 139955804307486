import { FC, useEffect, useState } from "react";
import { CarDataType } from "data/types";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import { useApp } from "data/app-provider";
import FleetCard from "components/FleetCard/FleetCard";
import CarsListingModel, { CarsListing } from "models/CarsListingModel";
import { useNavigate } from "react-router-dom";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: CarDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
}) => {
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const navigate = useNavigate();
  const { ourFleet, setSelectedCar } = useApp();
  let maxPrice = ourFleet
    ? ourFleet.listings.reduce(
        (acc, current) => Math.max(acc, current.pricing.amount / 100),
        0
      )
    : 0;
  const [filteredFleet, setFilteredFleet] = useState<CarsListingModel | null>(
    ourFleet
  );
  const [rangePrices, setRangePrices] = useState([0, maxPrice]);

  useEffect(() => {
    if (
      selectedTypes.length == 0 ||
      Array.from(
        new Set(
          ourFleet!.listings.map((car) => car?.vehicle?.vehicle_class?.name)
        )
      ).filter((item): item is string => item !== undefined).length ==
        selectedTypes.length
    ) {
      setFilteredFleet(ourFleet);
    } else {
      setFilteredFleet({
        success: true,
        listings: ourFleet!.listings.filter((item: CarsListing) =>
          selectedTypes.includes(item?.vehicle?.vehicle_class?.name ?? "")
        ),
      });
    }
  }, [selectedTypes, ourFleet]);

  useEffect(() => {
    setFilteredFleet({
      success: true,
      listings: ourFleet!.listings.filter(
        (item: CarsListing) =>
          item.pricing.amount / 100 >= rangePrices[0] &&
          item.pricing.amount / 100 <= rangePrices[1]
      ),
    });
  }, [rangePrices]);

  const renderCard = (car: CarsListing) => {
    return (
      <FleetCard
        key={car.l_id}
        data={car}
        simpleImg={true}
        onClick={() => {
          navigate("/car-details");
          setSelectedCar(car);
        }}
      />
    );
  };
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
      style={{ minHeight: "80vh" }}
    >
      <div className="flex flex-col sm:flex-row justify-between align-center">
        <span className="lg:text-6xl md:text-3xl text-3xl font-semibold lg:leading-[73.14px] leading-12 text-left mb-4 text-[#3B3B3B]">
          Our Fleet
        </span>
        <div className="mb-8 lg:mb-0 flex items-center">
          <TabFilters
            types={[
              ...Array.from(
                new Set(
                  ourFleet!.listings.map(
                    (car) => car?.vehicle?.vehicle_class?.name
                  )
                )
              ).filter((item): item is string => item !== undefined),
            ]}
            selectedTypes={selectedTypes}
            setSelectedTypes={setSelectedTypes}
            rangePrices={rangePrices}
            setRangePrices={setRangePrices}
            maxPrice={maxPrice}
          />
        </div>
      </div>
      <div className="nc-SectionGridFeaturePlaces relative">
        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
        >
          {filteredFleet?.listings.map((car) => renderCard(car))}
        </div>
        <div className="flex mt-16 justify-center items-center">
          {/* <ButtonPrimary loading>Show me more</ButtonPrimary> */}
        </div>
      </div>
      {/* <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {data.map((car) => (
          <CarCard key={car.id} data={car} />
        ))}
      </div> */}
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

export default SectionGridFilterCard;
