import { FC, Fragment, useEffect, useRef, useState } from "react";
import { formatDateTime } from "utils/converSelectedDateToString";
import { useApp } from "data/app-provider";
import {
  Checkbox,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { CreateBookingResponseModel } from "models/CreateBookingResponseModel";
import { StripeCardElementOptions } from "@stripe/stripe-js";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import { useNavigate } from "react-router-dom";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import RentalAgreement from "containers/PageHome/RentalAgreement";
import toast from "react-hot-toast";
import { purchaseInsurance } from "utils/apiUtil";
import { pdf } from "@react-pdf/renderer";
import { doc, setDoc } from "firebase/firestore";
import { storage, firestore, saveUserDetails } from "../../data/firebase";
import { ref, uploadBytes } from "firebase/storage";
import {
  Policies,
  PurchaseInsuranceResponse,
} from "models/PurchaseInsuranceResponse";
import { BookingsModel } from "models/BookingsModel";
import TermsAndConditions from "containers/PageHome/Term&Conditions";
import { globalJson } from "global/global_json";
import Cancellelation from "containers/PageHome/Cancellelation";

export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {
  const signatureStylesText = [
    "Playball",
    "Qwitcher Grypen",
    "Montez",
    "My Soul",
    "Ms Madi",
    "Niconne",
  ];

  const {
    user,
    setUser,
    pickupLocation,
    dropOffLocation,
    selectedDropOffLocationExtra,
    searchedPickupLoction,
    searchedDropoffLoction,
    startDate,
    endDate,
    selectedCar,
    selectedExtras,
    selectedBonzahProduct,
    selectedInsuranceProtection,
    subTotal,
    discount,
    discountName,
    discountPercentage,
    extrasTotal,
    totalTax,
    tripFee,
    deposit,
    showDeposit,
    grossTotal,
    protection,
    checkOut,
    checkoutLoading,
    checkCouponResponseLoading,
    callCouponAPI,
    couponResponse,
    calculateCouponDiscount,
    setCouponResponse,
    stripeVerification,
    emailToUser,
    selectedSignatureIndex,
    setSelectedSignatureIndex,
    axleInsurance,
    tripDays,
  } = useApp();

  const [inputValue, setInputValue] = useState("");
  let [isOpen, setIsOpen] = useState(false);
  let [isAgreementOpen, setIsAgreementOpen] = useState(false);
  let [isTermOpen, setIsTermOpen] = useState(false);
  let [isCancelOpen, setIsCancelOpen] = useState(false);
  let [rentalAgreementChecked, setRentalAgreementChecked] = useState(false);
  let [loadingCoupon, setLoadingCoupon] = useState(false);
  let [loadingDetail, setLoadingDetail] = useState(false);
  let [loading, setLoading] = useState(false);
  let [termsAndConditionsChecked, setTermsAndConditionsChecked] =
    useState(false);
  let [createBookingResponseModel, setCreateBookingResponseModel] =
    useState<CreateBookingResponseModel | null>();
  const [phoneNumber, setPhoneNumber] = useState(user?.phone ?? "");
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleSave = async () => {
    setLoadingDetail(true);
    const userId = user?.uid;
    if (!userId) {
      console.error("User ID is undefined");
      return;
    }

    const userDetails = { ...(user || {}), phone: phoneNumber };
    await saveUserDetails(userId, userDetails);
    setUser(userDetails);
    setLoadingDetail(false);
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!pickupLocation) {
      navigate("/");
    }
  }, [pickupLocation]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleCouponSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setLoadingCoupon(true);
    await callCouponAPI(inputValue);
    setLoadingCoupon(false);
  };

  // Function to save PDF to Firebase Storage
  const savePdfToStorage = async (blob: Blob, filePath: string) => {
    const storageRef = ref(storage, filePath);
    await uploadBytes(storageRef, blob);
  };

  // Function to generate PDF and upload to Firebase Storage
  const generateAndUploadPdf = async (
    bookingId: string,
    bonzahCertificate: string
  ) => {
    // Generate PDF document
    const pdfDoc = (
      <RentalAgreement
        selectedSignatureIndex={selectedSignatureIndex}
        stripeVerification={stripeVerification!}
        selectedBonzahProduct={selectedBonzahProduct}
        axleInsurance={axleInsurance}
        bonzahCertificate={bonzahCertificate}
        selectedCar={selectedCar}
        user={user}
      />
    );
    const asBlob = await pdf(pdfDoc).toBlob();

    // Save PDF to Firebase Storage
    const filePath = `agreements/${bookingId}.pdf`;
    await savePdfToStorage(asBlob, filePath);

    // Save reference to Firestore
    const docRef = doc(firestore, "bookings", bookingId);
    await setDoc(docRef, {
      id: bookingId,
      email: user!.email,
      start_date_time: startDate.toISOString(),
      end_date_time: endDate.toISOString(),
      pickup_location: searchedPickupLoction,
      drop_off_location: searchedDropoffLoction,
      total: grossTotal(),
      vehicle_name: selectedCar!.name,
      vehicle_id: selectedCar!.l_id,
      booking_status: "Reserved",
      vehicle_img:
        (selectedCar?.listing_images?.length ?? 0) > 1
          ? selectedCar?.listing_images[1].path
          : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg",
    } as BookingsModel);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setLoading(true);
    event.preventDefault();
    if (process.env.REACT_APP_ENV != "prod") {
      await paymentSuccessful();
    } else {
      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        return;
      }
      setLoading(true);
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        createBookingResponseModel?.StripePaymentIntent.client_secret ?? "",
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name:
                stripeVerification?.person_details.first_name ??
                "" + stripeVerification?.person_details.last_name,
            },
          },
        }
      );

      if (error) {
        toast.error(
          error.message ??
            "Unfortunately there was an error while processing the payment. Please check if the card details are correct."
        );
      } else {
        try {
          await paymentSuccessful();
        } catch (e: any) {
          toast.error(e);
        }
      }
    }
    setLoading(false);
  };

  const paymentSuccessful = async () => {
    // Handle successful payment here (e.g., display a success message, update order status)
    let purchaseInsuranceResponse = null;
    // if (selectedBonzahProduct.length != 0) {
    //   purchaseInsuranceResponse = (await purchaseInsurance({
    //     travelDetails: {
    //       destinationCountryCode: "USA",
    //       residenceCountryCode: "USA",
    //       residenceStateCode: globalJson?.residenceStateCode,
    //       departureDate: formatDateToCustomString(startDate),
    //       returnDate: formatDateToCustomString(endDate),

    //       dropOffTime: "Earlier",
    //     },

    //     products: selectedBonzahProduct.map((product) => product.productType),
    //     personalDetails: {
    //       firstName: stripeVerification?.person_details.first_name!,
    //       lastName: stripeVerification?.person_details.last_name!,
    //     },
    //     contactDetails: {
    //       email: user?.email!,
    //       phone: user?.phone!,
    //       ccEmails: [globalJson.ccEmail],
    //       bccEmails: [""],
    //     },
    //     address: {
    //       ...stripeVerification?.person_details.address!,
    //       ...{
    //         zipCode: stripeVerification?.person_details.address.postal_code!,
    //       },
    //     },
    //     customInformation: {
    //       customId: createBookingResponseModel?.b_id ?? "",
    //     },
    //   })) as PurchaseInsuranceResponse;
    // }
    await emailToUser(
      createBookingResponseModel?.b_id ?? "",
      purchaseInsuranceResponse
    );
    await generateAndUploadPdf(createBookingResponseModel?.b_id ?? "", "");
    navigate("/pay-done");
    setCouponResponse(null);
  };

  const callCheckout = async () => {
    if (user?.phone === null || user?.phone === "" || !user?.phone) {
      toast.error("Please provide your phone number");
      return;
    }
    if (!termsAndConditionsChecked) {
      toast.error("Please accept the privacy policy and terms and conditions");
      return;
    }
    if (!rentalAgreementChecked) {
      toast.error("Please accept the rental agreement to proceed");
      return;
    }

    const response = await checkOut();
    setCreateBookingResponseModel(response);
    open();
  };

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full">
            <div className=" w-full aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover w-full"
                sizes="200px"
                src={
                  (selectedCar?.listing_images?.length ?? 0) > 0
                    ? selectedCar?.listing_images[0].path
                    : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
                }
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="w-full max-w-sm mx-auto">
            <h2 className="text-xl font-medium text-gray-900 dark:text-gray-100">
              Rental Bill
            </h2>
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
              Prices may change depending on the length of the rental and the
              price of your rental car.
            </p>
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Subtotal
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {" "}
                    ($
                    {((selectedCar?.pricing?.amount ?? 0) / 100).toFixed(
                      0
                    )} *{" "}
                    {(
                      subTotal /
                      ((selectedCar?.pricing?.amount ?? 1) / 100)
                    ).toFixed(0)}{" "}
                    days)
                  </span>
                </div>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${subTotal.toFixed(2)}
                </span>
              </div>
            </div>
            {selectedInsuranceProtection?.map((selectedBonzahOption) => (
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <div>
                    <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                      {selectedBonzahOption?.title ?? "Insurance Coverage"}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {" "}
                      ($
                      {((selectedBonzahOption?.price ?? 0) / 100).toFixed(
                        2
                      )} * {tripDays().toFixed(2)}
                      days)
                    </span>
                  </div>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    $
                    {(
                      ((selectedBonzahOption?.price ?? 0) / 100) *
                      tripDays()
                    ).toFixed(2)}
                  </span>
                </div>
              </div>
            ))}
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Custom Location Charges
                </span>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${((pickupLocation?.price ?? 0) / 100).toFixed(2)}
                </span>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Delivery Charges (Drop-off)
                </span>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  $
                  {((selectedDropOffLocationExtra?.price ?? 0) / 100).toFixed(
                    2
                  )}
                </span>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Extras
                </span>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${extrasTotal.toFixed(2)}
                </span>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Tax
                </span>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${totalTax.toFixed(2)}
                </span>
              </div>
            </div>

            {/* <div className="mt-4">
              <div className="flex justify-between items-center">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Booking Fee
                </span>
                <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                  ${tripFee.toFixed(2)}
                </span>
              </div>
            </div> */}

            {showDeposit === true && (
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Deposit Fee
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ${deposit.toFixed(2)}
                  </span>
                </div>
              </div>
            )}

            {discount !== 0 && (
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Discount
                  </span>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    -${discount.toFixed(2)}
                  </span>
                </div>
              </div>
            )}
            {discount !== 0 && (
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {discountName}
                  </span>
                  <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                    {discountPercentage}%
                  </span>
                </div>
              </div>
            )}

            {couponResponse !== null && (
              <div className="mt-4 ">
                <div className="flex justify-between items-center">
                  <div className="flex flex-wrap  border border-grey rounded-full py-1">
                    <span className="text-lg font-normal text-gray-700 dark:text-gray-300 pl-4">
                      {couponResponse?.coupon?.code}
                    </span>
                    <span className="text-lg text-green-600 font-medium pl-1">
                      (
                      {couponResponse?.coupon?.type === "amount"
                        ? `$${couponResponse?.coupon?.amount / 100} off`
                        : `${couponResponse?.coupon?.amount}% off`}
                      )
                    </span>
                    <ButtonCircle
                      onClick={() => {
                        setCouponResponse(null);
                      }}
                      size="w-6 h-6"
                      className=" self-center mr-2 ml-4"
                    >
                      <XMarkIcon className="w-4 h-4" />
                    </ButtonCircle>
                  </div>
                  <span className="text-2xl text-green-600 font-semibold">
                    -${calculateCouponDiscount().toFixed(2)}
                  </span>
                </div>
              </div>
            )}
            {couponResponse === null && (
              <form
                onSubmit={handleCouponSubmit}
                className="mt-10 relative max-w-sm"
              >
                <Input
                  value={inputValue}
                  onChange={handleInputChange}
                  required
                  aria-required
                  placeholder="Promo Code"
                  type="text"
                  rounded="rounded-full"
                />
                <ButtonCircle
                  type="submit"
                  className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
                >
                  {loadingCoupon ? (
                    <div className="flex justify-center items-center col-span-full">
                      <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                    </div>
                  ) : (
                    <i className="las la-arrow-right text-xl"></i>
                  )}
                </ButtonCircle>
              </form>
            )}
            <div className="mt-8 border-t border-gray-200 dark:border-gray-700 pt-4">
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Total Rental Price
                  </span>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                    Overall price rental
                  </p>
                </div>
                <div>
                  <span className="text-2xl font-medium text-gray-900 dark:text-gray-100">
                    ≈${grossTotal().toFixed(2)}
                  </span>
                </div>
              </div>
            </div>

            <button
              className="nc-Button w-full mt-4 relative h-auto items-center justify-center rounded-2xl transition-colors ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 px-4 py-3 sm:px-6 text-sm sm:text-base font-bold"
              onClick={callCheckout}
            >
              {checkoutLoading ? (
                <div className="flex justify-center items-center col-span-full">
                  <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                </div>
              ) : (
                "Pay and Book"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">Trip Details</h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <h3 className="text-2xl font-semibold">Location</h3>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <div className="flex flex-col flex-1 p-5">
              <span className="text-sm text-neutral-400">Pick up Location</span>
              <span className="mt-1.5 text-lg font-semibold">
                {pickupLocation?.name}
              </span>
            </div>

            <div className="flex flex-col flex-1 p-5">
              <span className="text-sm text-neutral-400">
                Drop off Location
              </span>
              <span className="mt-1.5 text-lg font-semibold">
                <span className="line-clamp-1">
                  {dropOffLocation
                    ? dropOffLocation?.name
                    : pickupLocation?.name}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-2xl font-semibold">Phone Number</h3>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <div className="flex flex-col flex-1 p-5">
              <label htmlFor="phone" className="text-sm text-neutral-400">
                Contact Number
              </label>
              <div className="flex mt-1.5">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="block w-full px-3 py-2 border-none rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-transparent text-lg font-semibold"
                  placeholder="Enter your phone number"
                />
                <button
                  type="button"
                  onClick={handleSave}
                  className="ml-2 px-4 py-2 bg-primary-500 text-white rounded-md text-lg font-semibold min-w-[80px]"
                >
                  {loadingDetail ? (
                    <div className="flex justify-center items-center col-span-full">
                      <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                    </div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Date & Time</h3>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <div className="flex flex-col flex-1 p-5">
              <span className="text-sm text-neutral-400">
                Pick up Date & Time
              </span>
              <span className="mt-1.5 text-lg font-semibold">
                {formatDateTime(startDate)}
              </span>
            </div>

            <div className="flex flex-col flex-1 p-5">
              <span className="text-sm text-neutral-400">
                Drop off Date & Time
              </span>
              <span className="mt-1.5 text-lg font-semibold">
                <span className="line-clamp-1">{formatDateTime(endDate)}</span>
              </span>
            </div>
          </div>
        </div>
        <div>
          {selectedExtras.size > 0 && (
            <h3 className="text-2xl font-semibold">Trip Extras</h3>
          )}
          {Array.from(selectedExtras.values()).map((extra) => (
            <div className="mt-6 p-4 border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden z-10">
              <div className="text-gray-400 text-lg font-medium ">
                Added Extra
              </div>
              <div className="text-gray-800 text-xl font-bold ">
                {extra.title}
              </div>
            </div>
          ))}
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Terms and Conditions</h3>
          <div className="flex gap-2 mt-6 p-4 border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden z-10">
            <Checkbox
              checked={termsAndConditionsChecked}
              onChange={(checked) => {
                setTermsAndConditionsChecked(checked);
              }}
              className="group size-14 rounded-md  p-1 ring-1 ring-checkbox-bg ring-inset data-[checked]:bg-checkbox-bg mr-4"
            >
              <CheckIcon
                className={`size-12 stroke-checkbox-tick ${
                  termsAndConditionsChecked ? "visible" : "invisible"
                }`}
              />
            </Checkbox>
            <div>
              <div className="text-gray-400 text-lg font-medium ">
                I agree to the terms and conditions & cancellation policy
              </div>
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div
                  className="text-gray-800 text-xl font-bold cursor-pointer underline"
                  onClick={() => {
                    setIsTermOpen(true);
                  }}
                >
                  Terms & Conditions{" "}
                </div>
                <div className="text-gray-800 text-xl font-bold">&</div>
                <div
                  className="text-gray-800 text-xl font-bold cursor-pointer underline"
                  onClick={() => {
                    setIsCancelOpen(true);
                  }}
                >
                  Cancellation Policy
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-2 mt-6 p-4 border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden z-10">
            <Checkbox
              checked={rentalAgreementChecked}
              onChange={(checked) => {
                setIsAgreementOpen(true);
              }}
              className="group size-14 rounded-md  p-1 ring-1 ring-checkbox-bg ring-inset data-[checked]:bg-checkbox-bg mr-4"
            >
              <CheckIcon
                className={`size-12 stroke-checkbox-tick ${
                  rentalAgreementChecked ? "visible" : "invisible"
                }`}
              />
            </Checkbox>
            <div>
              <div className="text-gray-400 text-lg font-medium ">
                I've reviewed and accepted the Rental Agreement
              </div>
              <div
                className="text-gray-800 dark:text-gray-200 text-xl font-bold cursor-pointer underline"
                onClick={() => {
                  setIsAgreementOpen(true);
                }}
              >
                Rental Agreement{" "}
              </div>
            </div>
          </div>
          <div className="overflow-x-auto lg:hidden flex mt-6">
            {signatureStylesText.map((font, index) => (
              <div
                key={index}
                className="mr-4 w-64"
                onClick={() => {
                  setSelectedSignatureIndex(index);
                }}
              >
                <div
                  className={` border-2 rounded-lg mb-3 p-4 flex items-center ${
                    selectedSignatureIndex === index
                      ? "border-primary-200 shadow-lg"
                      : "border-gray-200"
                  }`}
                >
                  <p
                    style={{ fontFamily: font }}
                    className="text-l text-secondary w-64"
                  >
                    {`${stripeVerification?.person_details?.first_name ?? ""} ${
                      stripeVerification?.person_details?.last_name ?? ""
                    }`}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="lg:hidden flex-grow">{renderSidebar()}</div>
        <div className="px-4 py-2">
          <div className="hidden lg:flex lg:overflow-x-scroll">
            {signatureStylesText.map((font, index) => (
              <div
                key={index}
                className="mr-4 w-64"
                onClick={() => {
                  setSelectedSignatureIndex(index);
                }}
              >
                <div
                  className={` border-2 rounded-lg mb-3 p-4 flex items-center ${
                    selectedSignatureIndex === index
                      ? "border-primary-200 shadow-lg"
                      : "border-gray-200"
                  }`}
                >
                  <p
                    style={{ fontFamily: font }}
                    className="text-l text-secondary w-64"
                  >
                    {`${stripeVerification?.person_details?.first_name ?? ""} ${
                      stripeVerification?.person_details?.last_name ?? ""
                    }`}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const cardStyle: StripeCardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  function padToTwoDigits(number: number) {
    return number.toString().padStart(2, "0");
  }

  function formatDateToCustomString(date: Date) {
    const year = date.getFullYear();
    const month = padToTwoDigits(date.getMonth() + 1); // getMonth() returns 0-based month
    const day = padToTwoDigits(date.getDate());
    const hours = padToTwoDigits(date.getHours());
    const minutes = padToTwoDigits(date.getMinutes());
    const seconds = padToTwoDigits(date.getSeconds());

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  }

  return (
    <>
      <div className={`nc-CheckOutPagePageMain ${className}`}>
        <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
          <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
            {renderMain()}
          </div>
          <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
        </main>
      </div>
      <Transition appear show={isOpen}>
        <Dialog
          as="div"
          className="relative z-10 focus:outline-none"
          onClose={close}
        >
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 transform-[scale(95%)]"
                enterTo="opacity-100 transform-[scale(100%)]"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 transform-[scale(100%)]"
                leaveTo="opacity-0 transform-[scale(95%)]"
              >
                <DialogPanel className="w-full max-w-md bg-primary-6000 rounded-x p-6 backdrop-blur-2xl rounded-xl">
                  <div className="flex justify-end">
                    <button onClick={close} className="text-white">
                      <XMarkIcon className="h-6 w-6" />
                    </button>
                  </div>
                  <DialogTitle
                    as="h3"
                    className="font-medium m-5 text-white text-2xl"
                  >
                    Payment
                  </DialogTitle>
                  <form onSubmit={handleSubmit} className="m-5">
                    <label
                      htmlFor="card-element"
                      className="bloc font-sm mb-2 text-white text-xl"
                    >
                      Card Details
                    </label>
                    <div className="border rounded-lg p-3">
                      <CardElement
                        id="card-element"
                        options={cardStyle}
                        className="p-3"
                      />
                    </div>{" "}
                    <ButtonPrimary
                      type="submit"
                      disabled={!stripe}
                      loading={loading}
                      className="w-full mt-4 bg-white hover:bg-white text-primary-6000"
                    >
                      {`Pay $${
                        (createBookingResponseModel?.StripePaymentIntent
                          ?.amount ?? 0) / 100
                      }`}
                    </ButtonPrimary>
                  </form>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isAgreementOpen} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={() => {
            setIsAgreementOpen(false);
          }}
          static={true}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <DialogPanel className="relative h-full w-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4 z-0">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={close}
                      >
                        <XMarkIcon
                          onClick={() => {
                            setIsAgreementOpen(false);
                          }}
                          className="w-5 h-5 text-black dark:text-white"
                        />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800 z-10">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <div className="p-5 ">
                            <span className="block font-semibold text-xl sm:text-2xl">
                              Rental Agreement{" "}
                            </span>
                          </div>
                          <div className="flex-1 relative flex z-10 ">
                            <div className="grid justify-center w-full overflow-hidden rounded-3xl ">
                              <RentalAgreement
                                selectedSignatureIndex={selectedSignatureIndex}
                                stripeVerification={stripeVerification!}
                                selectedBonzahProduct={selectedBonzahProduct}
                                axleInsurance={axleInsurance}
                                selectedCar={selectedCar}
                                user={user}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" justify-end px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex">
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          setRentalAgreementChecked(true);
                          setIsAgreementOpen(false);
                        }}
                      >
                        Accept
                      </ButtonPrimary>
                    </div>
                  </>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isTermOpen} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={() => {
            setIsTermOpen(false);
          }}
          static={true}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <DialogPanel className="relative h-full w-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4 z-0">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={close}
                      >
                        <XMarkIcon
                          onClick={() => {
                            setIsTermOpen(false);
                          }}
                          className="w-5 h-5 text-black dark:text-white"
                        />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800 z-10">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <div className="flex-1 relative flex z-10 ">
                            <div className="grid justify-center w-full overflow-hidden rounded-none p-6">
                              <TermsAndConditions />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" justify-end px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex">
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          setTermsAndConditionsChecked(true);
                          setIsTermOpen(false);
                        }}
                      >
                        Accept
                      </ButtonPrimary>
                    </div>
                  </>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isCancelOpen} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={() => {
            setIsCancelOpen(false);
          }}
          static={true}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <DialogPanel className="relative h-full w-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4 z-0">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={close}
                      >
                        <XMarkIcon
                          onClick={() => {
                            setIsCancelOpen(false);
                          }}
                          className="w-5 h-5 text-black dark:text-white"
                        />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800 z-10">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <div className="flex-1 relative flex z-10 ">
                            <div className="grid justify-center w-full overflow-hidden rounded-none p-6">
                              <Cancellelation />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" justify-end px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex">
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          setTermsAndConditionsChecked(true);
                          setIsCancelOpen(false);
                        }}
                      >
                        Accept
                      </ButtonPrimary>
                    </div>
                  </>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CheckOutPagePageMain;
