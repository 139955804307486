"use client";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import CarsListingModel, { CarsListing } from "models/CarsListingModel";
import {
  checkCoupon,
  createBooking,
  emailBookingConfirmation,
  getAvailableCarsListing,
  getBonzahQuote,
  getCarDailyPricing,
  getCarsListing,
  getCompanyData,
  getExtras,
  getFleetwireCustomer,
  getStripeVerificationDetails,
} from "utils/apiUtil";
import toast from "react-hot-toast";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, firestore } from "./firebase";
import { addDays } from "date-fns";
import {
  CompanyModel,
  DeliveryLocations,
  TaxCategories,
} from "models/CompanyModel";
import { ExtrasModel, Extras, ExtrasOptions } from "models/ExtrasModel";
import BonzahProducts, { BonzahProduct } from "models/BonzahProducts";
import UserDocModel from "models/UserModel";
import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import {
  FleetwireCustomer,
  Insurance,
  StripeVerification,
} from "models/FleetwireCustomer";
import CarDailyPricing from "models/CarDailyPricing";
import { CreateBookingResponseModel } from "models/CreateBookingResponseModel";
import CheckCouponResponse from "models/CheckCouponResponse";
import { globalJson } from "global/global_json";
import { PurchaseInsuranceResponse } from "models/PurchaseInsuranceResponse";
import { StripeVerificationReportData } from "models/StripeVerificationReport";
import { BookingsModel } from "models/BookingsModel";

interface AppContextType {
  user: UserDocModel | null;
  setUser: React.Dispatch<React.SetStateAction<UserDocModel | null>>;
  fleetwireCustomer: FleetwireCustomer | null;
  setFleetwireCustomer: React.Dispatch<
    React.SetStateAction<FleetwireCustomer | null>
  >;
  isAuthPanelOpen: boolean;
  setIsAuthPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  axleInsurance: Insurance | null;
  stripeVerification: StripeVerification | null;
  fleetwireToken: string | null;
  setFleetwireToken: React.Dispatch<React.SetStateAction<string | null>>;
  dropOffLocationType: "same" | "different";
  setDropOffLocationType: React.Dispatch<
    React.SetStateAction<"same" | "different">
  >;

  startDate: Date;
  startHours: string;
  startMinutes: string;
  startPeriod: string;
  endDate: Date;
  endHours: string;
  endMinutes: string;
  endPeriod: string;

  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setStartHours: React.Dispatch<React.SetStateAction<string>>;
  setStartMinutes: React.Dispatch<React.SetStateAction<string>>;
  setStartPeriod: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndHours: React.Dispatch<React.SetStateAction<string>>;
  setEndMinutes: React.Dispatch<React.SetStateAction<string>>;
  setEndPeriod: React.Dispatch<React.SetStateAction<string>>;

  pickupLocation: DeliveryLocations | null;
  setPickupLocation: React.Dispatch<
    React.SetStateAction<DeliveryLocations | null>
  >;
  dropOffLocation: DeliveryLocations | null;
  setDropOffLocation: React.Dispatch<
    React.SetStateAction<DeliveryLocations | null>
  >;
  searchedPickupLoction: string;
  setSearchedPickupLocation: React.Dispatch<React.SetStateAction<string>>;
  searchedDropoffLoction: string;
  setSearchedDropoffLocation: React.Dispatch<React.SetStateAction<string>>;
  ourFleet: CarsListingModel | null;
  companyData: CompanyModel | null;
  availableFleet: CarsListingModel | null;
  extras: Extras[];
  dropOffLocationExtras: Extras | null;
  insuranceProtection: Extras | null;
  selectedCar: CarsListing | null;
  setSelectedCar: React.Dispatch<React.SetStateAction<CarsListing | null>>;
  selectedExtras: Map<string, Extras>;
  setSelectedExtras: React.Dispatch<React.SetStateAction<Map<string, Extras>>>;
  bonzahProducts: BonzahProducts | null;
  selectedBonzahProduct: BonzahProduct[];
  setSelectedBonzahProduct: React.Dispatch<
    React.SetStateAction<BonzahProduct[]>
  >;
  selectedDropOffLocationExtra: ExtrasOptions | null;
  setSelectedDropOffLocationExtra: React.Dispatch<
    React.SetStateAction<ExtrasOptions | null>
  >;
  selectedInsuranceProtection: ExtrasOptions[];
  setSelectedInsuranceProtection: React.Dispatch<
    React.SetStateAction<ExtrasOptions[]>
  >;

  selectedSignatureIndex: number;
  setSelectedSignatureIndex: React.Dispatch<React.SetStateAction<number>>;

  //calculations
  subTotal: number;
  protection: number;
  discountPercentage: number;
  discount: number;
  discountName: string;
  extrasTotal: number;
  totalBeforeTax: number;
  totalTax: number;
  tripFee: number;
  deposit: number;
  showDeposit: boolean;
  totalTaxExcludingExtrasTax: number;
  couponResponse: CheckCouponResponse | null;
  setCouponResponse: React.Dispatch<
    React.SetStateAction<CheckCouponResponse | null>
  >;
  //loading
  fleetwireCustomerLoading: boolean;
  availableFleetLoading: boolean;
  carDailyPricingLoading: boolean;
  companyDataLoading: boolean;
  extrasLoading: boolean;
  bonzahLoading: boolean;
  ourFleetLoading: boolean;
  checkoutLoading: boolean;
  checkCouponResponseLoading: boolean;
  logoutCall: (navigate: any) => void;
  tripDays: () => number;
  callAvailableFleetAPI: (navigate: any) => Promise<void>;
  callBonzahAPI: () => Promise<void>;
  callFleetwireCustomerAPI: () => Promise<void>;
  grossTotal: () => number;
  checkOut: () => Promise<CreateBookingResponseModel>;
  callCouponAPI: (couponCode: string) => Promise<void>;
  calculateCouponDiscount: () => number;
  emailToUser: (
    bookingId: string,
    purchaseInsuranceResponse: PurchaseInsuranceResponse | null
  ) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export function AppProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<UserDocModel | null>(null);
  const [fleetwireCustomer, setFleetwireCustomer] =
    useState<FleetwireCustomer | null>(null);
  const [stripeVerification, setStripeVerification] =
    useState<StripeVerification | null>(null);
  const [axleInsurance, setAxleInsurance] = useState<Insurance | null>(null);
  const [fleetwireToken, setFleetwireToken] = useState<string | null>(null);
  let [isAuthPanelOpen, setIsAuthPanelOpen] = useState<boolean>(false);
  //state of the app
  const [dropOffLocationType, setDropOffLocationType] = useState<
    "same" | "different"
  >("same");

  const [startDate, setStartDate] = useState<Date>(() => {
    const newDate = addDays(new Date(), 2); // Create a new date 2 days from now
    newDate.setHours(10, 0, 0, 0); // Set the time to 10 AM (hours, minutes, seconds, milliseconds)
    return newDate;
  });
  const [startHours, setStartHours] = useState("8");
  const [startMinutes, setStartMinutes] = useState("00");
  const [startPeriod, setStartPeriod] = useState("AM");

  const [endDate, setEndDate] = useState<Date>(() => {
    const newDate = addDays(new Date(), 4); // Create a new date 2 days from now
    newDate.setHours(17, 0, 0, 0); // Set the time to 10 AM (hours, minutes, seconds, milliseconds)
    return newDate;
  });

  const [endHours, setEndHours] = useState("8");
  const [endMinutes, setEndMinutes] = useState("00");
  const [endPeriod, setEndPeriod] = useState("PM");

  const [pickupLocation, setPickupLocation] =
    useState<DeliveryLocations | null>(null);
  const [dropOffLocation, setDropOffLocation] =
    useState<DeliveryLocations | null>(null);
  const [searchedPickupLoction, setSearchedPickupLocation] =
    useState<string>("");
  const [searchedDropoffLoction, setSearchedDropoffLocation] =
    useState<string>("");
  const [selectedCar, setSelectedCar] = useState<CarsListing | null>(null);
  const [carDailyPricing, setCarDailyPricing] =
    useState<CarDailyPricing | null>(null);
  const [selectedExtras, setSelectedExtras] = useState<Map<string, Extras>>(
    new Map()
  );
  const [selectedBonzahProduct, setSelectedBonzahProduct] = useState<
    BonzahProduct[]
  >([]);
  const [selectedSignatureIndex, setSelectedSignatureIndex] =
    useState<number>(0);

  //data from api
  const [ourFleet, setOurFleet] = useState<CarsListingModel | null>({
    success: true,
    listings: [],
  });
  const [availableFleet, setAvailableFleet] = useState<CarsListingModel | null>(
    {
      success: true,
      listings: [],
    }
  );
  const [companyData, setCompanyData] = useState<CompanyModel | null>(null);
  const [extras, setExtras] = useState<Extras[]>([]);
  const [dropOffLocationExtras, setDropOffLocationExtras] =
    useState<Extras | null>(null);
  const [selectedDropOffLocationExtra, setSelectedDropOffLocationExtra] =
    useState<ExtrasOptions | null>(null);
  const [insuranceProtection, setInsuranceProtection] = useState<Extras | null>(
    null
  );
  const [selectedInsuranceProtection, setSelectedInsuranceProtection] =
    useState<ExtrasOptions[]>([]);
  const [couponResponse, setCouponResponse] =
    useState<CheckCouponResponse | null>(null);
  const [bonzahProducts, setBonzahProducts] = useState<BonzahProducts | null>(
    null
  );

  //loading
  const [ourFleetLoading, setOurFleetLoading] = useState<boolean>(false);
  const [availableFleetLoading, setAvailableFleetLoading] =
    useState<boolean>(false);
  const [carDailyPricingLoading, setCarDailyPricingLoading] =
    useState<boolean>(false);
  const [companyDataLoading, setCompanyDataLoading] = useState<boolean>(false);
  const [extrasLoading, setExtrasLoading] = useState<boolean>(false);
  const [bonzahLoading, setBonzahLoading] = useState<boolean>(false);
  const [fleetwireCustomerLoading, setFleetwireCustomerLoading] =
    useState<boolean>(false);
  const [checkCouponResponseLoading, setCheckCouponResponseLoading] =
    useState<boolean>(false);
  const [checkoutLoading, setCheckoutLoading] = useState<boolean>(false);

  //calculations
  const [subTotal, setSubTotal] = useState<number>(0);
  const [protection, setProtection] = useState<number>(0);
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);
  const [discount, setDiscount] = useState<number>(0);
  const [discountName, setDiscountName] = useState<string>("");
  const [extrasTotal, setExtrasTotal] = useState<number>(0);
  const [extrasTotalTaxable, setExtrasTotalTaxable] = useState<number>(0);
  const [totalBeforeTax, setTotalBeforeTax] = useState<number>(0);
  const [totalTax, setTotalTax] = useState<number>(0);
  const [totalTaxExcludingExtrasTax, setTotalTaxExcludingExtrasTax] =
    useState<number>(0);
  const [tripFee, setTripFee] = useState<number>(0);
  const [taxes, setTaxes] = useState<{ [key: string]: number }>({});
  const [deposit, setDeposit] = useState<number>(0);
  const [showDeposit, setShowDeposit] = useState<boolean>(false);

  useEffect(() => {
    if (selectedCar != null) {
      extrasSubTotal();
      calculateSubtotalAndProtectionForDailyPricing();
    }
  }, [
    selectedExtras,
    selectedBonzahProduct,
    carDailyPricing,
    checkCoupon,
    selectedCar,
    extrasTotal,
    selectedInsuranceProtection,
  ]);

  useEffect(() => {
    if (selectedCar != null) {
      calculateDiscount();
    }
  }, [
    selectedExtras,
    selectedBonzahProduct,
    carDailyPricing,
    checkCoupon,
    selectedCar,
    extrasTotal,
    subTotal,
    selectedInsuranceProtection,
    totalTax,
  ]);

  useEffect(() => {
    if (selectedCar != null) {
      calculateTax();
    }
  }, [
    selectedExtras,
    selectedBonzahProduct,
    carDailyPricing,
    checkCoupon,
    selectedCar,
    extrasTotal,
    subTotal,
    selectedInsuranceProtection,
  ]);

  useEffect(() => {
    if (user != null) {
      settingFleetwireToken();
    }
  }, [user]);

  useEffect(() => {
    setSelectedBonzahProduct([]);
    setSelectedExtras(new Map());

    if (selectedCar != null) callCarDailyPricing();
  }, [selectedCar]);

  useEffect(() => {
    callOurFleetAPI();
    callCompanyAPI();
    callExtrasAPI();

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const userDocRef = doc(firestore, "users", currentUser?.uid!);
        const userDoc = await getDoc(userDocRef);
        const userDetails = userDoc.data() as UserDocModel;
        setUser(userDetails);
      }
    });
    return () => unsubscribe();
  }, []);

  const checkOut = async () => {
    setCheckoutLoading(true);
    const createBookingModel: any = {
      l_id: selectedCar?.l_id || "",
      dates: {
        start: startDate.toLocaleString("sv-SE").replace(" ", "T"),
        end: endDate.toLocaleString("sv-SE").replace(" ", "T"),
      },
      customers: [
        {
          isPrimary: true,
          firstName: stripeVerification?.person_details.first_name ?? "",
          lastName: stripeVerification?.person_details.last_name ?? "",
          email: user?.email!,
          phone: user?.phone,
          phone_number: user?.phone,
          dob: "1987-09-11",
          age: 27,
          agreeAdoptSignature: false,
          agreeToS: true,
        },
      ],
      extrasV2: Array.from(selectedExtras.values()),
      requiredExtrasV2: [],
      delivery: pickupLocation,
      delivery_address: null,
    };
    if (selectedInsuranceProtection.length != 0) {
      selectedInsuranceProtection.map((selectedBonzahOption) =>
        createBookingModel["requiredExtrasV2"].push({
          parentUuid: insuranceProtection?.uuid,
          choice: {
            uuid: selectedBonzahOption?.uuid,
          },
        })
      );
    }
    if (selectedDropOffLocationExtra != null) {
      createBookingModel["requiredExtrasV2"].push({
        parentUuid: dropOffLocationExtras?.uuid,
        choice: {
          uuid: selectedDropOffLocationExtra?.uuid,
        },
      });
    }
    let customLocation = true;
    companyData?.company?.delivery_locations.forEach((location) => {
      if (searchedPickupLoction == location?.name) {
        customLocation = false;
      }
    });
    if (customLocation == true) {
      createBookingModel["delivery_address"] = {
        name: searchedPickupLoction, //required
        address_line1: searchedPickupLoction, //required
        address_line2: "",
        address_level1: "",
        address_level2: "",
        postcode: "33602", //required
        country: "United States",
        country_code: "us",
        district: "",
        neighborhood: "",
        provider: "google", //required
        provider_id: "",
        lat: 0,
        lng: 0,
      };
    }

    if (couponResponse) {
      createBookingModel.discounts = {
        discounted: true,
        code: couponResponse.coupon.code,
        amount: couponResponse.coupon.amount,
        type: couponResponse.coupon.type,
      };
    }

    const response = (await createBooking(
      createBookingModel
    )) as CreateBookingResponseModel;
    setCheckoutLoading(false);
    return response;
  };

  const calculateSubtotalAndProtectionForDailyPricing = () => {
    setSubTotal(0);
    setProtection(0);
    let tripDaysIteration = tripDays();
    for (let dailyPricing of carDailyPricing?.dailyPricing ?? []) {
      setSubTotal((prev) => prev + dailyPricing.price / 100);
      if (selectedInsuranceProtection) {
        selectedInsuranceProtection.map((selectedBonzahOption) =>
          setProtection(
            (prev) => prev + (selectedBonzahOption?.price ?? 0) / 100
          )
        );
      }
      tripDaysIteration--;
      if (tripDaysIteration === 0) break;
    }
  };

  const calculateDiscount = () => {
    setDiscountPercentage(0);
    setDiscount(0);
    let discountApplied: boolean = false;

    if (selectedCar?.pricing?.discountable === true) {
      if (tripDays() >= 30 && selectedCar?.pricing.monthlyPercentageDiscount) {
        setDiscountPercentage(
          selectedCar?.pricing.monthlyPercentageDiscount ?? 0
        );
        setDiscountName("Monthly Percentage Discount");
        discountApplied = true;
      }
      if (
        tripDays() >= 7 &&
        selectedCar?.pricing.weeklyPercentageDiscount &&
        discountApplied === false
      ) {
        setDiscountPercentage(
          selectedCar?.pricing.weeklyPercentageDiscount ?? 0
        );
        setDiscountName("Weekly Percentage Discount");
        discountApplied = true;
      }
      if (
        tripDays() >= 3 &&
        selectedCar?.pricing.threeDayPercentageDiscount &&
        discountApplied === false
      ) {
        setDiscountPercentage(
          selectedCar?.pricing.threeDayPercentageDiscount ?? 0
        );
        setDiscountName("Three Days Percentage Discount");
      }
    }
    const newDiscount =
      ((selectedCar?.pricing?.discountable === true ? subTotal : 0) +
        (pickupLocation?.discountable == 1 ? pickupLocation?.price ?? 0 : 0) /
          100 +
        totalTaxExcludingExtrasTax) *
      (discountPercentage / 100);

    setDiscount(newDiscount);
    setTotalBeforeTax(subTotal - newDiscount);
  };

  const extrasSubTotal = () => {
    setExtrasTotal(0);
    setExtrasTotalTaxable(0);
    selectedExtras.forEach((extras) => {
      if (extras.type == "day") {
        if (extras.taxable == 1) {
          setExtrasTotalTaxable(
            (prev) => prev + (extras.options[0].price / 100) * tripDays()
          );
        }
        setExtrasTotal(
          (prev) => prev + (extras.options[0].price / 100) * tripDays()
        );
      } else {
        if (extras.taxable == 1) {
          setExtrasTotalTaxable(
            (prev) => prev + (extras.options[0].price / 100) * tripDays()
          );
        }
        setExtrasTotal((prev) => prev + extras.options[0].price / 100);
      }
    });
  };

  const calculateTax = () => {
    let newTaxes: { [key: string]: number } = {};
    let newTotalTax = 0;
    let newTotalTaxExcludingExtrasTax = 0;

    if ((companyData?.company?.tax_categories?.length ?? 0) > 0) {
      let defaultTaxCategory: TaxCategories =
        companyData?.company?.tax_categories[0]!;
      companyData?.company?.tax_categories.filter((taxCategory) => {
        if (taxCategory.default === true) defaultTaxCategory = taxCategory;
      });

      if (defaultTaxCategory !== null) {
        defaultTaxCategory.tax_rates.forEach((taxRate) => {
          const rate = parseFloat(taxRate?.percentage ?? "0") / 100;
          //trip fee
          if (taxRate.name == "Trip Fee") {
            setTripFee(parseFloat(taxRate?.amount ?? 0) / 100);
          } else {
            if (taxRate?.percentage != null) {
              const taxAmount =
                ((selectedCar?.pricing?.taxable === true ? subTotal : 0) +
                  (pickupLocation?.taxable === 1
                    ? (pickupLocation?.price ?? 0) / 100
                    : 0)) *
                rate;
              newTaxes[`${taxRate.name} (${rate}%)`] = taxAmount;
              newTotalTax += taxAmount;
            } else if (taxRate?.percentage == null) {
              if (taxRate.type == "trip") {
                newTotalTax = newTotalTax + parseFloat(taxRate.amount) / 100;
              } else if (taxRate.type == "unit") {
                newTotalTax =
                  newTotalTax + (parseFloat(taxRate.amount) / 100) * tripDays();
              }
            }
          }
        });
      }
    }
    newTotalTaxExcludingExtrasTax = newTotalTax;

    setTaxes(newTaxes);
    setTotalTax(newTotalTax);
    setTotalTaxExcludingExtrasTax(newTotalTaxExcludingExtrasTax);
  };

  const calculateCouponDiscount = () => {
    if (!couponResponse) return 0;
    if (couponResponse.coupon.type === "amount") {
      return couponResponse.coupon.amount / 100;
    } else {
      let deliveryPrice =
        pickupLocation?.method.toLowerCase() == "delivery"
          ? (pickupLocation?.price ?? 0) / 100
          : 0;
      if (pickupLocation?.discountable !== 1) {
        deliveryPrice = 0;
      }
      return (
        (subTotal + totalTaxExcludingExtrasTax + deliveryPrice) *
        (couponResponse.coupon.amount / 100)
      );
    }
  };

  const calculateCouponDiscountForOnlyTripPrice = () => {
    if (!couponResponse) return 0;
    if (couponResponse.coupon.type === "amount") {
      return 0;
    } else {
      return subTotal - subTotal * (couponResponse.coupon.amount / 100);
    }
  };

  const calculateCouponDiscountForOnlyDeliveryPrice = () => {
    if (!couponResponse) return 0;
    if (couponResponse.coupon.type === "amount") {
      return 0;
    } else {
      return (
        (pickupLocation!.price / 100 || 0) -
        (pickupLocation!.price / 100 || 0) *
          (couponResponse.coupon.amount / 100)
      );
    }
  };

  const grossTotal = () => {
    const couponDiscount = calculateCouponDiscount();
    const newGrossTotal =
      (couponResponse == null ? subTotal : subTotal - couponDiscount) -
      discount +
      totalTax +
      tripFee +
      extrasTotal +
      protection +
      (showDeposit == true ? deposit : 0) +
      (pickupLocation?.price ?? 0) / 100 +
      (selectedDropOffLocationExtra?.price ?? 0) / 100;
    return newGrossTotal;
  };

  const tripDays = () => {
    const tripHours = Math.ceil(
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60)
    );
    return Math.ceil(tripHours / 24);
  };

  const recalculateTotalBeforeTax = () => {
    const couponDiscount = calculateCouponDiscount();
    const newTotalBeforeTax =
      (couponResponse == null ? subTotal : subTotal - couponDiscount) -
      discount;
    setTotalBeforeTax(newTotalBeforeTax);
    calculateTax();
  };

  const settingFleetwireToken = async () => {
    if (user?.renterId != null) {
      const tokenDocRef = await getDoc(
        doc(firestore, "Tokens", "FleetwireAdmin")
      );
      setFleetwireToken(tokenDocRef.data()?.key);
      callFleetwireCustomerAPI(tokenDocRef.data()?.key);
    }
  };

  const deleteSession = (navigate: any) => {
    signOut(auth);
    setUser(null);
    setFleetwireCustomer(null);
    navigate("/");
  };

  const callOurFleetAPI = async () => {
    try {
      setOurFleetLoading(true);
      const data = (await getCarsListing()) as CarsListingModel;
      setOurFleet(data);
    } catch (error: any) {
      toast.error(error.message); // Or handle errors in a more sophisticated way
    } finally {
      setOurFleetLoading(false);
    }
  };

  function convertTimeToDays(timeStr: any) {
    try {
      const [value, unit] = timeStr.split("-");
      const valueNum = parseFloat(value);
      const unitConversion: any = {
        hour: valueNum / 24,
        day: valueNum,
        week: valueNum * 7,
      };
      return unitConversion[unit.toLowerCase()] || null;
    } catch (error) {
      return null; // Invalid format
    }
  }

  const callAvailableFleetAPI = async (navigate: any) => {
    try {
      setAvailableFleetLoading(true);
      const data = (await getAvailableCarsListing(
        startDate,
        endDate
      )) as CarsListingModel;
      setAvailableFleet({
        success: true,
        listings: data.listings
          .filter(
            (element) =>
              pickupLocation?.branch?.location?.address_level2
                .toLowerCase()
                .includes(
                  element.vehicle.branch == null
                    ? "not including car with null branch"
                    : element.vehicle.branch.location.address_level2.toLowerCase()
                ) && element.available.available == true
          )
          .filter((car) => {
            return (
              (endDate.getTime() - startDate.getTime()) /
                (1000 * 60 * 60 * 24) >=
                (convertTimeToDays(car.minimum_request_duration) ?? 0) &&
              (endDate.getTime() - startDate.getTime()) /
                (1000 * 60 * 60 * 24) <=
                (convertTimeToDays(car.maximum_request_duration) ?? 1000) &&
              car.active !== false &&
              car.paused !== true
            );
          }),
      });
    } catch (error: any) {
      toast.error(error.message); // Or handle errors in a more sophisticated way
    } finally {
      navigate("/available-car");
      setAvailableFleetLoading(false);
    }
  };

  const callCarDailyPricing = async () => {
    try {
      setCarDailyPricingLoading(true);
      const data = (await getCarDailyPricing(
        selectedCar?.l_id ?? "",
        startDate,
        endDate
      )) as CarDailyPricing;
      setCarDailyPricing(data);
    } catch (error: any) {
      toast.error(error.message); // Or handle errors in a more sophisticated way
    } finally {
      setCarDailyPricingLoading(false);
    }
  };

  const callExtrasAPI = async () => {
    try {
      setExtrasLoading(true);
      const data = (await getExtras()) as ExtrasModel;
      setExtras(data.extras.filter((extra) => extra.required == false));
      data.extras.map((extra) =>
        extra.title == "Bonzah" ? setInsuranceProtection(extra) : extra
      );
      data.extras.map((extra) =>
        extra.title == "Locations" ? setDropOffLocationExtras(extra) : extra
      );
    } catch (error: any) {
      toast.error(error.message); // Or handle errors in a more sophisticated way
    } finally {
      setExtrasLoading(false);
    }
  };

  const callBonzahAPI = async () => {
    try {
      setBonzahLoading(true);
      const data = (await getBonzahQuote(startDate, endDate)) as BonzahProducts;
      setBonzahProducts(data);
    } catch (error: any) {
      toast.error(error.message); // Or handle errors in a more sophisticated way
    } finally {
      setBonzahLoading(false);
    }
  };

  const callCompanyAPI = async () => {
    try {
      setCompanyDataLoading(true);
      const data = (await getCompanyData()) as CompanyModel;
      setCompanyData(data);
      console.log(data);
      data.company.defaultMeta.forEach((meta) => {
        if (meta.meta_key === "pricing.deposit_value") {
          setDeposit((meta.meta_value ?? 0) / 100);
        }
        if (meta.meta_key === "store.payment_deposit") {
          setShowDeposit(meta.meta_value ?? false);
        }
      });
    } catch (error: any) {
      toast.error(error.message); // Or handle errors in a more sophisticated way
    } finally {
      setCompanyDataLoading(false);
    }
  };

  const callFleetwireCustomerAPI = async (token?: string) => {
    try {
      setFleetwireCustomerLoading(true);
      const data = (await getFleetwireCustomer(
        user?.renterId ?? "",
        token ?? fleetwireToken ?? ""
      )) as FleetwireCustomer;
      setFleetwireCustomer(data);
      for (const insurance of data.renter.insurance) {
        if (insurance.results && insurance.results.isActive) {
          setAxleInsurance(insurance);
          break;
        }
      }
      for (const stripeVerification of data.renter.stripe_verifications) {
        console.log(stripeVerification);

        if (stripeVerification.status == "verified") {
          if (stripeVerification.person_details === null) {
            const data = (await getStripeVerificationDetails(
              stripeVerification.document_vr_id ?? ""
            )) as StripeVerificationReportData;
            console.log(data);
            setStripeVerification(
              mapVerificationReportToStripeVerification(data)
            );
          } else {
            setStripeVerification(stripeVerification);
          }
          break;
        }
      }
    } catch (error: any) {
      toast.error(error.message); // Or handle errors in a more sophisticated way
    } finally {
      setFleetwireCustomerLoading(false);
    }
  };

  function mapVerificationReportToStripeVerification(
    report: StripeVerificationReportData
  ): StripeVerification {
    return {
      b_id: "", // Assuming this is an external ID not provided in the report
      customer_id: "", // Assuming this is an external ID not provided in the report
      vs_id: report.verification_session,
      vip_id: null, // Assuming not provided in the report
      redirect_to_url: "", // Assuming not provided in the report
      status: report.document.status,
      document_vr_id: report.id,
      document_status: report.document.status,
      selfie_vr_id: null, // Assuming not provided in the report
      selfie_files: null, // Assuming not provided in the report
      selfie_status: null, // Assuming not provided in the report
      last_error: report.document.error, // Maps to last_error
      person_details: {
        document_number: "", // Assuming this is not provided in the report
        expiration_date: null,
        issued_date: report.document.issued_date,
        issuing_country: report.document.issuing_country,
        first_name: report.document.first_name,
        last_name: report.document.last_name,
        address: {
          city: report.document.address.city,
          country: report.document.address.country,
          line1: report.document.address.line1,
          line2: "", // Assuming line2 is not provided in the report
          postal_code: report.document.address.postal_code,
          state: report.document.address.state,
        },
        dob: null, // Assuming DOB is not provided in the report
        document_image: report.document.files[0] || "", // Assuming the first file is the document image
        selfie_image: "", // Assuming no selfie image provided in the report
      },
      created_at: new Date(report.created * 1000).toISOString(), // Convert Unix timestamp to ISO string
      updated_at: new Date().toISOString(), // Assuming updated_at is the current time
    };
  }

  const callCouponAPI = async (couponCode: string) => {
    try {
      setCheckCouponResponseLoading(true);
      const bookingsRef = collection(firestore, "bookings");
      const q = query(bookingsRef, where("email", "==", user?.email));
      const querySnapshot = await getDocs(q);
      const bookings = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) => {
          return { ...doc.data(), id: doc.id } as BookingsModel;
        }
      );
      if (bookings.length > 0 && couponCode.includes("FirstTimeDiscount")) {
        toast.error("Coupon has expired");
      } else {
        const data = await checkCoupon(couponCode, user?.email ?? "");
        setCouponResponse(data as CheckCouponResponse);
      }
    } catch (error: any) {
      toast.error("Invalid Promo Code"); // Or handle errors in a more sophisticated way
    } finally {
      setCheckCouponResponseLoading(false);
    }
  };

  const emailToUser = async (
    bookingId: string,
    purchaseInsuranceResponse: PurchaseInsuranceResponse | null
  ) => {
    function formatDate(date: Date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const year = date.getFullYear();

      return `${month}/${day}/${year}`;
    }
    function formatTime(date: Date) {
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${hours}:${minutes}`;
    }
    // Helper function to extract insurances
    const extractInsurances = () => {
      const insurances: Record<string, string> = {};
      if (purchaseInsuranceResponse?.order?.policies != null) {
        purchaseInsuranceResponse.order.policies.forEach((policy) => {
          const productName = policy.product?.name ?? "Unknown Product";
          const certificateUrl = policy.certificateUrl ?? "";
          insurances[productName] = certificateUrl;
        });
      }
      return insurances;
    };

    try {
      setCheckoutLoading(true);
      const templateParams = {
        recipients: [user?.email, globalJson.ccEmail, "1now.deck@gmail.com"],
        message_type: "booking_confirmation",
        sender: globalJson.sender,
        content: {
          carName: selectedCar?.name,
          carImage:
            (selectedCar?.listing_images?.length ?? 0) > 0
              ? selectedCar?.listing_images[0].path
              : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg",

          RenterName:
            stripeVerification?.person_details.first_name +
            " " +
            stripeVerification?.person_details.last_name,
          carPlate: selectedCar?.vehicle.plate,
          DlNumber: stripeVerification?.person_details.document_number,
          PickupLocationName: pickupLocation?.name,
          PickupLocationLink: `https://maps.google.com/?q=${
            pickupLocation!.branch.location.lat
          },${pickupLocation!.branch.location.lng}`,
          DropoffLocationName: dropOffLocation
            ? dropOffLocation?.name
            : pickupLocation?.name,
          DropoffLocationLink: `https://maps.google.com/?q=${
            dropOffLocation
              ? dropOffLocation!.branch.location.lat
              : pickupLocation!.branch.location.lat
          },${
            dropOffLocation
              ? dropOffLocation!.branch.location.lng
              : pickupLocation!.branch.location.lng
          }`,
          PickupDate: formatDate(startDate),
          PickupTime: formatTime(startDate),
          DropoffDate: formatDate(endDate),
          DropoffTime: formatTime(endDate),
          Insurances: extractInsurances(),
          ExtraName: Array.from(selectedExtras.values()).map(
            (extra) => extra.title
          ),
          CarPrice: `($${((selectedCar?.pricing?.amount ?? 0) / 100).toFixed(
            0
          )} * ${(
            subTotal /
            ((selectedCar?.pricing?.amount ?? 1) / 100)
          ).toFixed(0)}days)`,
          CarTotal: "$" + subTotal.toFixed(2),
          InsurancePrice: "",
          ExtrasPrice: "",
          ExtrasTotal: "$" + extrasTotal.toFixed(2),
          LocationPrice: "",
          LocationTotal: `${((pickupLocation?.price ?? 0) / 100).toFixed(2)}`,
          TaxAndFeePrice: "",
          TaxAndFeeTotal: "$" + totalTax.toFixed(2),
          Coupon: couponResponse?.coupon?.code,
          DayDiscount:
            discount == 0
              ? ""
              : discountName + ' "' + discountPercentage + '%"',
          DiscountTotal: discount == 0 ? "" : "-$" + discount.toFixed(2),
          DepositFeeTotal: "$" + deposit.toFixed(2),
          BookingFeeTotal: "$" + tripFee.toFixed(2),
          SubTotal: `≈${grossTotal().toFixed(2)}`,
          WebsiteLink: globalJson.websiteLink,
          RentalAgreement:
            globalJson?.agreement_key + bookingId + globalJson?.bookingId_key,
        },
      };
      await emailBookingConfirmation(templateParams);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setCheckoutLoading(false);
    }
  };

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        fleetwireCustomer,
        setFleetwireCustomer,
        axleInsurance,
        stripeVerification,
        fleetwireToken,
        setFleetwireToken,
        dropOffLocationType,
        setDropOffLocationType,
        startDate,
        startHours,
        startMinutes,
        startPeriod,
        setStartHours,
        setStartMinutes,
        setStartPeriod,
        endDate,
        endHours,
        endMinutes,
        endPeriod,
        setEndHours,
        setEndMinutes,
        setEndPeriod,
        setStartDate,
        setEndDate,
        pickupLocation,
        setPickupLocation,
        dropOffLocation,
        setDropOffLocation,
        searchedPickupLoction,
        setSearchedPickupLocation,
        setSearchedDropoffLocation,
        searchedDropoffLoction,
        ourFleet,
        companyData,
        availableFleet,
        extras,
        dropOffLocationExtras,
        selectedDropOffLocationExtra,
        setSelectedDropOffLocationExtra,
        insuranceProtection,
        selectedCar,
        setSelectedCar,
        selectedExtras,
        setSelectedExtras,
        bonzahProducts,
        selectedBonzahProduct,
        setSelectedBonzahProduct,
        selectedInsuranceProtection,
        setSelectedInsuranceProtection,
        selectedSignatureIndex,
        setSelectedSignatureIndex,
        isAuthPanelOpen,
        setIsAuthPanelOpen,
        subTotal,
        protection,
        discountPercentage,
        discount,
        discountName,
        extrasTotal,
        totalBeforeTax,
        totalTax,
        tripFee,
        deposit,
        showDeposit,
        totalTaxExcludingExtrasTax,
        couponResponse,
        setCouponResponse,
        //loading
        ourFleetLoading,
        availableFleetLoading,
        carDailyPricingLoading,
        companyDataLoading,
        extrasLoading,
        bonzahLoading,
        fleetwireCustomerLoading,
        checkoutLoading,
        checkCouponResponseLoading,
        logoutCall: deleteSession,
        tripDays,
        callAvailableFleetAPI: callAvailableFleetAPI,
        callBonzahAPI: callBonzahAPI,
        callFleetwireCustomerAPI: callFleetwireCustomerAPI,
        grossTotal,
        checkOut,
        callCouponAPI,
        calculateCouponDiscount,
        emailToUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useApp() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useApp must be used within an AppProvider");
  }
  return context;
}
