import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef, FC } from "react";
import { useApp } from "data/app-provider";
import { DeliveryLocations } from "models/CompanyModel";
import toast from "react-hot-toast";
import LatLng from "models/Latlng";
import {
  getLocationFromLatLong,
  getNearbyLocations,
  searchLocations,
} from "utils/apiUtil";

interface Props {
  onClick?: () => void;
  onChange?: (value: string) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;
  setShowModal: (show: boolean) => void;
}

const LocationInput: FC<Props> = ({
  onChange = () => {},
  className = "",
  defaultValue = "United States",
  headingText = "Where to?",
  setShowModal,
}) => {
  const {
    companyData,
    setPickupLocation,
    pickupLocation,
    setDropOffLocation,
    dropOffLocationExtras,
    setSelectedDropOffLocationExtra,
    setSearchedPickupLocation,
    setSearchedDropoffLocation,
  } = useApp();
  const [value, setValue] = useState("");
  const [searchedLocations, setSearchedLocations] = useState<any>();
  const [companyDataLoading, setCompanyDataLoading] = useState<boolean>(true);

  const containerRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleSelectLocation = (item: DeliveryLocations) => {
    setTimeout(() => {
      setValue(item.name);
      onChange && onChange(item.name);
      if (headingText === "Pick up?") {
        setPickupLocation(item);
        setSearchedPickupLocation(item?.name ?? "");
      } else if (headingText === "Drop off?") {
        setDropOffLocation(item);
        setSearchedDropoffLocation(item?.name ?? "");
        dropOffLocationExtras?.options.map((location) => {
          if (location.title == item.name) {
            setSelectedDropOffLocationExtra(location);
          }
        });
      }
    }, 0);
  };
  const callSearchLocationsAPI = async (input: string) => {
    try {
      setCompanyDataLoading(true);
      const data = (await searchLocations(input)) as any;

      setSearchedLocations(data.predictions);
    } catch (error: any) {
      toast.error(error.message); // Or handle errors in a more sophisticated way
    } finally {
      setCompanyDataLoading(false);
    }
  };
  function radians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }
  async function checkLocationInRange(placeId: string): Promise<LatLng | null> {
    try {
      const placeDetailsResponse = (await getNearbyLocations(placeId)) as any;

      if (placeDetailsResponse.result) {
        // Extract the latlng from the result
        const lat = placeDetailsResponse.result.geometry.location.lat;
        const lng = placeDetailsResponse.result.geometry.location.lng;
        return {
          latitude: lat,
          longitude: lng,
        };
      } else {
        console.error(
          `Place Details API error: ${placeDetailsResponse.status}`
        );
      }
    } catch (error) {
      console.error("Error fetching location details:", error);
    }

    return null;
  }

  function calculateDistance(start: LatLng, end: LatLng): number {
    const earthRadius = 6371; // Earth's radius in meters

    const lat1 = radians(start.latitude);
    const lon1 = radians(start.longitude);
    const lat2 = radians(end.latitude);
    const lon2 = radians(end.longitude);

    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c;

    return distance;
  }

  useEffect(() => {
    callSearchLocationsAPI(value);
  }, [value]);

  const handleSelectSearchedLocation = async (item: any) => {
    const position = (await checkLocationInRange(
      item.place_id
    )) as LatLng | null;
    let minDistanceDeliveryLocation: DeliveryLocations | null = null;
    let minDistance = Infinity;
    let locationsNearUser = [];

    if (position) {
      for (const location of companyData?.company?.delivery_locations ?? []) {
        if (location.distance != null) {
          const startLatLng = {
            latitude: Number(location.location.lat),
            longitude: Number(location.location.lng),
          };

          const endLatLng = {
            latitude: Number(position.latitude),
            longitude: Number(position.longitude),
          };
          const distance = calculateDistance(startLatLng, endLatLng);

          if (distance / 1.609344 <= location.distance) {
            if (minDistanceDeliveryLocation) {
              if (minDistance > distance) {
                minDistanceDeliveryLocation = location;
                minDistance = distance;
              }
            } else {
              minDistanceDeliveryLocation = location;
              minDistance = distance;
            }
          }
        }
      }
    }

    if (minDistanceDeliveryLocation) {
      locationsNearUser.push(minDistanceDeliveryLocation);
      if (headingText === "Pick up?") {
        setSearchedPickupLocation(item.description);
        setPickupLocation(minDistanceDeliveryLocation);
      } else if (headingText === "Drop off?") {
        setDropOffLocation(minDistanceDeliveryLocation);
        setSearchedDropoffLocation(item.description);
      }
      onChange && onChange(item.description);
      setValue(item.description);
    } else {
      setShowModal(false);
      toast.error("Sorry, not yet available! Search for a nearby location.");
    }
  };
  const renderRecentSearches = () => {
    return (
      <>
        {/* <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
          Recent searches
        </h3> */}

        <div className="mt-2">
          {companyData &&
            companyData?.company?.delivery_locations
              .filter((item) => item.name !== "Custom Location")
              .sort((a, b) => a.price - b.price) // Filter out the "custom location"
              .map((item) => (
                <>
                  <span
                    onClick={() => handleSelectLocation(item)}
                    key={item.uuid}
                    className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer justify-between"
                  >
                    <div className="flex">
                      <span className="block text-neutral-400 max-sm:mt-1 mt-0">
                        <MapPinIcon className="h-4 sm:h-6 w-4 sm:w-6" />
                      </span>
                      <span className="text-left block font-medium text-neutral-700 dark:text-neutral-200 mx-2">
                        {item.name}
                      </span>
                    </div>
                    <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                      {item.price == 0
                        ? "Free"
                        : "$" + item.price / 100 + "/trip"}
                    </span>
                  </span>
                </>
              ))}
        </div>
      </>
    );
  };

  const renderSearchValue = () => {
    return (
      <>
        {searchedLocations?.map((item: any, index: number) => (
          <>
            <span
              onClick={() => handleSelectSearchedLocation(item)}
              key={index}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer justify-between"
            >
              <div className="flex">
                <span className="block text-neutral-400 max-sm:mt-1 mt-0">
                  <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                </span>
                <span className="block font-medium text-neutral-700 dark:text-neutral-200 mx-2">
                  {item["description"]}
                </span>
              </div>
            </span>
          </>
        ))}
      </>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className="block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate"
            placeholder={"Search destinations"}
            value={value}
            onChange={(e) => {
              setValue(e.currentTarget.value);
            }}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          {value ? renderSearchValue() : renderRecentSearches()}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
