import { UserCircleIcon, HomeIcon } from "@heroicons/react/24/outline";
import { useApp } from "data/app-provider";
import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import MenuBar from "shared/MenuBar/MenuBar";
import isInViewport from "utils/isInViewport";

let WIN_PREV_POSITION = window.pageYOffset;

interface NavItem {
  name: string;
  link?: string; // Changed from PathName to string
  icon: React.ComponentType<any> | JSX.Element;
}

const FooterNav: React.FC = () => {
  const { user } = useApp();
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const NAV: NavItem[] = [
    {
      name: "Home",
      link: "/",
      icon: HomeIcon,
    },
    user
      ? {
          name: "Profile",
          link: "/account",
          icon: (
            <Avatar
              sizeClass="w-8 h-8 sm:w-9 sm:h-9"
              imgUrl={user?.photoURL ?? ""}
            />
          ),
        }
      : {
          name: "Log in",
          link: "/login",
          icon: UserCircleIcon,
        },
    {
      name: "Menu",
      icon: <MenuBar />,
    },
  ];

  useEffect(() => {
    const handleEvent = () => {
      window.requestAnimationFrame(showHideHeaderMenu);
    };

    window.addEventListener("scroll", handleEvent);
    return () => {
      window.removeEventListener("scroll", handleEvent);
    };
  }, []);

  const showHideHeaderMenu = () => {
    let currentScrollPos = window.pageYOffset;
    if (!containerRef.current) return;

    if (currentScrollPos > WIN_PREV_POSITION) {
      if (
        isInViewport(containerRef.current) &&
        currentScrollPos - WIN_PREV_POSITION < 80
      ) {
        return;
      }

      containerRef.current.classList.add("FooterNav--hide");
    } else {
      if (
        !isInViewport(containerRef.current) &&
        WIN_PREV_POSITION - currentScrollPos < 80
      ) {
        return;
      }
      containerRef.current.classList.remove("FooterNav--hide");
    }

    WIN_PREV_POSITION = currentScrollPos;
  };

  const renderIcon = (
    icon: React.ComponentType<any> | JSX.Element,
    active: boolean
  ) => {
    const className = `w-6 h-6 ${active ? "text-primary-100" : ""}`;

    if (React.isValidElement(icon)) {
      return React.cloneElement(icon, {
        className,
      } as React.HTMLAttributes<HTMLElement>);
    } else {
      const IconComponent = icon as React.ComponentType<any>;
      return <IconComponent className={className} />;
    }
  };

  return (
    <div
      
    >
      {/* <div className="w-full max-w-lg flex justify-around mx-2 text-sm text-center p-5">
        {NAV.map((item, index) => {
          const active = location.pathname === item.link;
          return item.link ? (
            <Link
              key={index}
              to={item.link}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-neutral-900 dark:text-neutral-100" : ""
              }`}
            >
              {renderIcon(item.icon, active)}
              <span className="text-[11px] leading-none mt-1">{item.name}</span>
            </Link>
          ) : (
            <div
              key={index}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-neutral-900 dark:text-neutral-100" : ""
              }`}
            >
              {renderIcon(item.icon, active)}
              <span className="text-[11px] leading-none mt-1">{item.name}</span>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default FooterNav;
