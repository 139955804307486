import clientSay1 from "images/clientSay1.jpg";
import clientSay4 from "images/clientSay4.jpg";
import clientSay5 from "images/clientSay5.jpg";

export const globalJson = {
  // apiUtil auth header key
  authToken: "Bearer 168|3oLFn8OniSxk1UBTO3eQeGXMuViDTsZqAwhalwWm",
  companyId: "7514a219-48ce-439e-a2c5-6f38980cee1a",
  stripePublishableKey:
    "pk_live_51PWMfBP3eOUqNAzjUlJCyp5Gzc8ABMeCJNu7TXTH7JFb7DL2VYI2bAF726JBxcTxBobOB7vxrDzEVhoUPIPCrqyU00l7voPgBF",
  mapKey: "AIzaSyDKEntg7JydrxI7zr2tQL5OYcS5M-er-bc",
  residenceStateCode: "AZ",

  //email confirmation admin token
  adminEmailToken:
    "XR85jFku2zMMqvsYmAdkrk1lm6pozj7eFXetRHLNSSsLxRLnAHgY3dPaVsE27OK8cIbnhFQmncWxVjS1XLAhneFEFpwVVeaNE7hMOym2vrhzdKSmxn99V0fLvVJyZLPT",
  websiteLink: "https://autorentalsaz-dev.1now.app",
  sender: "autorentalsaz",
  ccEmail: "Autorentalsaz@gmail.com",

  // apiUtil sendNewsLetterEmail
  util_serviceId: "service_2xca7kj",
  util_templateId: "template_smckeo7",
  util_userId: "ATdsFr5wumje11YkV",
  util_privateKey: "TJyyV_e7nNOGPKbyyjpMB",
  util_contact_templateId: "template_9dsuz0w",

  // apiUtil getBonzahToken
  client_secret_dev: "",
  client_id_dev: "",
  client_secret_prod: "",
  client_id_prod: "",

  agreement_key:
    "https://firebasestorage.googleapis.com/v0/b/autorentalsaz-35923.appspot.com/o/agreements%2F",
  bookingId_key: ".pdf?alt=media&token=45faf783-a425-44c6-ada7-63c6fb41f682",

  //   bg_config
  firebaseConfig: {
    apiKey: "AIzaSyCfr0dw1SK2_OIqg4jPOeRw0DrW5zNeUX0",
    authDomain: "autorentalsaz-35923.firebaseapp.com",
    projectId: "autorentalsaz-35923",
    storageBucket: "autorentalsaz-35923.appspot.com",
    messagingSenderId: "366779547512",
    appId: "1:366779547512:web:f2747bcf2b9a228bcfd28d",
    measurementId: "G-EZVQLQZ9ZQ",
  },

  about_us_para:
    "In 2024, I turned my lifelong passion for cars and service into a reality by starting my private car rental business Auto Rentals AZ. As a family man who has five wonderful children, I understand the importance of reliable service and genuine care. Our mission is to provide you with a unique and tailored car rental experience, ensuring every journey is smooth and enjoyable. We offer a diverse selection of vehicles, perfect for any adventure, whether you’re traveling solo or with family. We aim to ensure that every rental experience is memorable. Thank you for choosing us as your trusted partner on the road. We look forward to being part of your adventure.",

  clientSays_arr: [
    {
      id: 1,
      clientName: "Jorge V",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "Pick up was easy and smooth. The car is well maintained very clean drives nice definitely would get it again.",
      img: clientSay1,
    },
    {
      id: 2,
      clientName: "Brooke F.",
      clientAddress: "LondDodge Grand Caravan 2019",
      content:
        "The car was as shown in photos. It was great for our party of seven on our trip. Sam was accommodating and helpful the entire trip. We will use this car again the next time we do a family trip like this",
      img: clientSay4,
    },
    {
      id: 3,
      clientName: "Justice B.",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "I loved the pickup/drop off location. Was right off the highway making it easy to get back on and drive to your destination. Sam was very communicative and accommodating. allowed me to pick up earlier than expected as well change my drop off time. Also, loved the car!",
      img: clientSay5,
    },
  ],

  //social links
  facebook:
    "https://www.facebook.com/profile.php?id=61560083231842&mibextid=LQQJ4d",
  instagram:
    "https://www.instagram.com/autorentalsazz?igsh=Mnc2cXZmbTl1aXFs&utm_source=qr",

  //contact
  address: "Phoenix AZ 85085",
  addressLink:
    "https://www.google.com/maps/place/4830+W+Kennedy+Blvd,+Tampa,+FL+33609,+USA/data=!4m2!3m1!1s0x88c2c2e4b61fbe87:0x7fa31f86943d0895?sa=X&ved=1t:242&ictx=111",
  phone: "+1 (248) 219-4849", //pending
  email: "Autorentalsaz@gmail.com", //pending
};

export const scriptHubSpot = document.createElement("script");
scriptHubSpot.src = "//js-na1.hs-scripts.com/46776911.js";
scriptHubSpot.async = true;
scriptHubSpot.defer = true;
scriptHubSpot.id = "hs-script-loader";
