import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { useApp } from "data/app-provider";
import { Insurance, StripeVerification } from "models/FleetwireCustomer";
import { BonzahProduct } from "models/BonzahProducts";
import { CarsListing } from "models/CarsListingModel";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    flexDirection: "column",
    display: "flex",
  },
  heading1: {
    fontWeight: "bold",
    fontSize: 22,
    color: "#000000",
  },
  heading2: {
    fontWeight: "bold",
    fontSize: 18,
    color: "#000000",
  },
  heading3: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#000000",
  },
  text: {
    fontSize: 12,
    color: "#000000",
  },
  textBold: {
    fontWeight: "bold",
    fontSize: 12,
    color: "#000000",
  },
  textUnderline: {
    fontWeight: "bold",
    fontSize: 12,
    color: "#000000",
    textDecoration: "underline",
  },
  section: {
    marginTop: 10,
    marginBottom: 10,
  },
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    marginBottom: 10,
    flexDirection: "column",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tableCol: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
  },
  tableCell: {
    margin: 5,
    fontSize: 12,
  },
  tableHeader: {
    fontWeight: "bold",
    backgroundColor: "#d3d3d3",
  },
});

const signatureStylesText = [
  "Playball",
  "Qwitcher Grypen",
  "Montez",
  "My Soul",
  "Ms Madi",
  "Niconne",
];

// Register fonts with direct URLs
Font.register({
  family: "Playball",
  src: "/fonts/Playball-Regular.ttf",
});
Font.register({
  family: "Qwitcher Grypen",
  src: "/fonts/QwitcherGrypen-Regular.ttf",
});
Font.register({
  family: "Montez",
  src: "/fonts/Montez-Regular.ttf",
});
Font.register({
  family: "My Soul",
  src: "/fonts/MySoul-Regular.ttf",
});
Font.register({
  family: "Ms Madi",
  src: "/fonts/MsMadi-Regular.ttf",
});
Font.register({
  family: "Niconne",
  src: "/fonts/Niconne-Regular.ttf",
});

// Create Document Component
export interface RentalAgreemaentProps {
  selectedSignatureIndex: number;
  stripeVerification: StripeVerification;
  selectedBonzahProduct: BonzahProduct[];
  axleInsurance: Insurance | null;
  bonzahCertificate?: string;
  selectedCar?: CarsListing | null;
  user?: any;
}

const RentalAgreement: React.FC<RentalAgreemaentProps> = ({
  selectedSignatureIndex,
  stripeVerification,
  axleInsurance,
  selectedCar,
  user,
}) => {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.heading1}>Autorentals AZ</Text>
        </View>
        <View style={styles.table}>
          <View style={{ ...styles.tableRow, ...styles.tableHeader }}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Vehicle Details</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Vehicle Name:
              </Text>
              <Text style={styles.tableCell}>{selectedCar?.name}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Year:
              </Text>
              <Text style={styles.tableCell}>{selectedCar?.vehicle.year}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Make:
              </Text>
              <Text style={styles.tableCell}>{selectedCar?.vehicle.make}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Model:
              </Text>
              <Text style={styles.tableCell}>{selectedCar?.vehicle.model}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                License Number:
              </Text>
              <Text style={styles.tableCell}>
                {selectedCar?.vehicle.plate
                  ? selectedCar?.vehicle.plate
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Vin:
              </Text>
              <Text style={styles.tableCell}>
                {selectedCar?.vehicle?.vin ? selectedCar?.vehicle.vin : "N/A"}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Miles Allowed:
              </Text>
              <Text style={styles.tableCell}>
                {selectedCar?.miles_included
                  ? selectedCar?.miles_included
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Excess Miles:
              </Text>
              <Text style={styles.tableCell}>
                {selectedCar?.miles_extra ? selectedCar?.miles_extra : "N/A"}
              </Text>
            </View>
          </View>
          <View style={{ ...styles.tableRow, ...styles.tableHeader }}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Renter Details</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Name:
              </Text>
              <Text style={styles.tableCell}>
                {stripeVerification.person_details.first_name}{" "}
                {stripeVerification.person_details.last_name}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Phone:
              </Text>
              <Text style={styles.tableCell}>{user.phone}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Email:
              </Text>
              <Text style={styles.tableCell}>{user.email}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Address:
              </Text>
              <Text style={styles.tableCell}>
                {stripeVerification?.person_details.address.line1}{" "}
                {stripeVerification?.person_details.address.line2}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                City:
              </Text>
              <Text style={styles.tableCell}>
                {stripeVerification?.person_details.address.city}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                State:
              </Text>
              <Text style={styles.tableCell}>
                {stripeVerification?.person_details.address.state}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                ZIP:
              </Text>
              <Text style={styles.tableCell}>
                {stripeVerification?.person_details.address.postal_code}
              </Text>
            </View>
          </View>
          <View style={{ ...styles.tableRow, ...styles.tableHeader }}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>License Details</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Driver License#:
              </Text>
              <Text style={styles.tableCell}>
                {stripeVerification?.person_details.document_number === ""
                  ? "N/A"
                  : stripeVerification?.person_details.document_number}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Issued Date:
              </Text>
              <Text style={styles.tableCell}>
                {stripeVerification?.person_details.issued_date?.month}/
                {stripeVerification?.person_details.issued_date?.day}/
                {stripeVerification?.person_details.issued_date?.year}
              </Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Date of Birth:
              </Text>
              <Text style={styles.tableCell}>
                {stripeVerification?.person_details.dob === null
                  ? "N/A"
                  : `${stripeVerification?.person_details.dob.month}/
                ${stripeVerification?.person_details.dob.day}/
                ${stripeVerification?.person_details.dob.year}`}
              </Text>
            </View>
          </View>
          <View style={{ ...styles.tableRow, ...styles.tableHeader }}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Insurance Details</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Insurance Company:
              </Text>
              <Text style={styles.tableCell}>
                {axleInsurance?.results.carrier}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={{ ...styles.tableCell, ...styles.textBold }}>
                Policy#:
              </Text>
              <Text style={styles.tableCell}>
                {axleInsurance?.results.policyNumber}
              </Text>
            </View>

            
          </View>
        </View>

        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            You will be in violation of A. R.S § 13-1806 If the Vehicle is not
            returned within 72 hours if the date and time the Vehicle is due
            back. If you fail to return the Vehicle within 72 hours of the date
            and time due in, you may be found guilty of a Class 5 felony that
            could result in a fine of up to $150,000 per charge and/or
            imprisonment of up to 2 years for the first offense.
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.textBold }}>
            THE OWNER DOES NOT EXTEND ANY OF ITS MOTOR VEHICLE FINANCIAL
            RESPONSIBILITY OR PROVIDE PUBLIC LIABILITY INSURANCE COVERAGE TO THE
            RENTER, AUTHORIZED DRIVERS OR ANY OTHER DRIVER.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            Any insurance policies that we offer may provide a duplication of
            coverage already provided by your personal automobile insurance
            policy or by another source of coverage. You are not required to
            purchase any insurance from us in order to rent the Vehicle.
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            NOTICE: Our liability insurance does not cover injuries to
            passengers in the Vehicle
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            In event of accident contact 911, please contact our office
            immediately.
          </Text>
        </View>
        <View>
          <Text
            style={{
              ...styles.text,
              ...styles.section,
              ...styles.textBold,
              ...styles.textUnderline,
            }}
          >
            Collision Damage Waiver (CDW)
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            By initialing here you agree to purchase our CDW. CDW does not cover
            all instances of damage to the Vehicle. There are exclusions.
            Subject to the terms of Paragraph 5 and 6 on the reverse, your
            responsibility for Collision Damage to the Vehicle is limited to $
            ________. ____ By initialing here you decline to purchase our CDW,
            and you agree to be responsible for all damage to or loss or theft
            of the Vehicle.
          </Text>
        </View>
        <View>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            Exclusions may apply to the following optional insurance products.
            Read the brochure(s) carefully.
          </Text>
        </View>
        <View>
          <Text
            style={{
              ...styles.text,
              ...styles.section,
              ...styles.textBold,
              ...styles.textUnderline,
            }}
          >
            Personal Accident Insurance (PAI)/Personal Effects Coverage (PEC)
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            By initialing here you agree to purchase PAI and PEC, a summary of
            the terms of which appears in a separate brochure that you
            acknowledge receiving.
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            By initialing here you decline to purchase PAI and PEC.
          </Text>
        </View>
        <View>
          <Text
            style={{
              ...styles.text,
              ...styles.section,
              ...styles.textBold,
              ...styles.textUnderline,
            }}
          >
            Supplemental Liability Insurance (SLI)
          </Text>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            By initialing here, you agree to purchase SLI and you acknowledge
            receiving a separate brochure that summarizes the terms of the SLI
            policy. SLI does not cover all risks.
          </Text>

          <Text
            style={{
              ...styles.text,
              ...styles.section,
              ...styles.textBold,
            }}
          >
            There may be exclusions for unauthorized or intoxicated drivers, no
            first party uninsured motorist coverage, no coverage for certain
            passengers in the Vehicle, and other exclusions.
          </Text>
          <Text
            style={{
              ...styles.text,
              ...styles.section,
            }}
          >
            Please read the SLI brochure carefully.
          </Text>
        </View>
        <View>
          <Text
            style={{
              ...styles.text,
              ...styles.section,
            }}
          >
            By initialing here,
          </Text>
          <Text
            style={{
              ...styles.text,
              ...styles.section,
              ...styles.textBold,
            }}
          >
            you decline to purchase SLI.{" "}
          </Text>
          <Text
            style={{
              ...styles.text,
              ...styles.section,
            }}
          >
            {" "}
            You agree to be responsible for, and you agree that your personal
            auto insurance coverage is primary for any damage or injury you
            cause to others and their property.
          </Text>
        </View>
        <View style={styles.section}>
          <Text
            style={{
              ...styles.text,
              ...styles.section,
            }}
          >
            {" "}
            By signing below, you agree to: the terms and conditions of this
            Rental Agreement, the Rental Agreement Terms & Conditions on the
            back side, vehicle inspection report and any signed addenda (the
            “Agreement”), and acknowledge that you had an opportunity to read
            the Agreement before signing; and authorize us to: process a
            separate payment card voucher in your name for all Charges, in-
            cluding Tolls and Violations; release your billing/rental
            information to third parties for billing/processing and other
            legitimate purpose; and adjust Charges with your payment card issuer
            after our final audit.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.heading2 }}>1. Definitions.</Text>
        </View>
        <View style={styles.section}>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            “Agreement”{" "}
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            means all terms and conditions found in the Rental Agreement,
            vehicle inspection form, this terms and conditions, any addenda and
            any additional documents you sign or we provide at the time of
            rental.
          </Text>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            “You” or “your”{" "}
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            means the person identified as the renter in this Agreement, each
            person signing this Agreement, each Authorized Driver, and every
            person or organization to whom charges are billed by us at its or
            the renter’s direction. All persons referred to as
          </Text>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            “You” or “your”{" "}
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            are jointly and severally bound by this Agreement.
          </Text>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            “We,” “our,”{" "}
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>or </Text>

          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            “us”{" "}
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            means the independent rental company named elsewhere in this
            Agreement.
          </Text>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            “Authorized Driver”{" "}
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            means the renter and each additional driver listed by us in this
            Agreement, as long as each such person has a valid driver’s license
            and is at least age 21. Only Authorized Drivers are permitted to use
            the Vehicle.
          </Text>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            “Vehicle”{" "}
          </Text>
          <View style={styles.section}>
            <Text style={{ ...styles.text, ...styles.section }}>
              means the automobile or truck identified in this Agreement and any
              vehicle we substitute for it, and all its tires, tools,
              accessories, equipment, keys and Vehicle documents.
            </Text>
          </View>
          <View>
            <Text style={{ ...styles.text, ...styles.section }}> “CDW” </Text>
          </View>
          <Text style={{ ...styles.text, ...styles.section }}>
            means Collision Damage Waiver.
          </Text>
          <View style={styles.section}>
            <Text
              style={{ ...styles.text, ...styles.section, ...styles.textBold }}
            >
              “Collision Damage”{" "}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={{ ...styles.text, ...styles.section }}>
              means damage to, or loss of, the Vehicle caused by collision or
              upset. Collision Damage does not include damage to tires, wheels
              or windshields; comprehensive damage such as damage to or loss of
              the Vehicle due to theft, vandalism, act of nature, riot or civil
              disturbance, hail, flood or fire; or other comprehensive loss not
              caused by collision or upset. “TWWD” means Tire, Wheel, and
              Windshield Damage Waiver.
            </Text>
          </View>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            “Loss of Use”{" "}
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            means the loss of our ability to use the Vehicle for our purposes
            due to Vehicle damage or loss during the Rental Period, including:
            use for rent, display for rent and sale, opportunity to upgrade or
            sell, or transportation of employees.
          </Text>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            “Diminished Value”{" "}
          </Text>
          <View style={styles.section}>
            <Text style={{ ...styles.text, ...styles.section }}>
              means the difference between the fair market value of the Vehicle
              before damage and its value after repairs as calculated by a
              third-party estimate obtained by us or on our behalf.
            </Text>
          </View>
          <View>
            <Text
              style={{ ...styles.text, ...styles.section, ...styles.textBold }}
            >
              {" "}
              “Charges”{" "}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={{ ...styles.text, ...styles.section }}>
              means the fees and charges incurred under this Agreement. All
              amounts expressed under this Agreement shall be payable in U.S.
              dollars.
            </Text>
          </View>
          <View>
            <Text
              style={{ ...styles.text, ...styles.section, ...styles.textBold }}
            >
              {" "}
              “RentalPeriod”{" "}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={{ ...styles.text, ...styles.section }}>
              means the period between the time that you take possession of the
              Vehicle and the time that the Vehicle is either returned to or
              recovered by us and checked in by us.
            </Text>
          </View>
          <View>
            <Text
              style={{ ...styles.text, ...styles.section, ...styles.textBold }}
            >
              {" "}
              “Vehicle License Fee,” “Vehicle Licensing,” “Vehicle License Prop
              Tax,” “Vehicle License Cost Recovery Fee,”{" "}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={{ ...styles.text, ...styles.section }}>or </Text>
          </View>
          <View>
            <Text
              style={{ ...styles.text, ...styles.section, ...styles.textBold }}
            >
              {" "}
              “Motor Vehicle Tax”{" "}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={{ ...styles.text, ...styles.section }}>
              {" "}
              means a vehicle license cost recovery fee based on our estimated
              average per day per vehicle portion of our total annual vehicle
              licensing, titling, and registration costs.
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={{ ...styles.heading2 }}>
              2. Nature of Rental; Condition and Return of Vehicle.{" "}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            This is a contract for the rental of the Vehicle only. You do not
            have the right to sublease the Vehicle. You must return the Vehicle
            to our rental office or other location we specify, on the date and
            time noted in this Agreement and in the same condition that you
            received it except for ordinary wear. To extend the Rental Period,
            you must first obtain our approval by contacting our rental office
            before the due-in date. If the Vehicle is returned after closing
            hours, you remain responsible for all loss of or damage to the
            Vehicle until we inspect it upon our next opening for business
            (regardless of when such loss or damage occurred), and Charges may
            continue to accrue until that time. Service to the Vehicle or
            replacement of parts or accessories during the Rental Period must
            have our prior written approval. You must check and maintain all
            fluid levels, and return the Vehicle with at least the same amount
            of fuel as when rented unless you purchase a prepaid fuel option.
          </Text>
          <View>
            <Text
              style={{ ...styles.text, ...styles.section, ...styles.textBold }}
            >
              {" "}
              To the extent permitted by law, we may repossess the Vehicle at
              your expense without notice to you, if the Vehicle is abandoned or
              used in violation of law or this Agreement.
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={{ ...styles.heading2 }}>
              3. Indemnity; No Warranties.{" "}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            To the fullest extent permitted by law, you agree to indemnify us,
            defend us and hold us harmless from all judgments, claims,
            liability, costs and attorney fees we incur resulting from, or
            arising out of, this rental, your use of the Vehicle or our
            repossession of it, and/or your use of optional equipment
          </Text>
          <View>
            <Text
              style={{ ...styles.text, ...styles.section, ...styles.textBold }}
            >
              {" "}
              (“Optional Equipment”). We make no warranties, express, implied or
              apparent, regarding the Vehicle or Optional Equipment that we rent
              to you for use in the vehicle, no warranty of merchantability and
              no warranty that the Vehicle or Optional Equipment is fit for a
              particular purpose.
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={{ ...styles.heading2 }}>
              4. Responsibility for Damage or Loss.{" "}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            You are responsible for all damage to, loss of, or theft of the
            Vehicle during the Rental Period, including damage caused by
            collision, weather, terrain conditions, and acts of God. Your
            responsibility will include: (a) all damage to the Vehicle measured
            as follows: (i) if we determine that the Vehicle is a total loss,
            the fair market value of the Vehicle, less salvage; (ii) if we
            determine that the Vehicle is repairable: (A) the difference between
            the value of the Vehicle immediately before the damage and the value
            immediately after the damage; or (B) the reasonable estimated retail
            value or actual cost of repair plus Diminished Value; (b) Loss of
            Use, which is measured by multiplying your daily rental rate by
            either the actual or estimated number of days from the date the
            Vehicle is damaged until it is replaced or repaired, which you agree
            represents a reasonable estimate of Loss of Use damages and not a
            penalty. Loss of Use is payable regardless of fleet utilization; (c)
            an administrative fee, calculated based on the damage repair
            estimate as follows, which you agree is reasonable: $0-$250
            damage=$50 fee; $251-$500 damage=$75 fee; $501-$750 damage=$100 fee;
            $751-$1500 damage=$150 fee; $1501-$2500 damage=$200 fee; over $2500
            damage=$250 fee; (d) towing, storage, and impound charges and other
            reasonable incidental and consequential damages; and (e) all costs
            associated with our enforcement of this Agreement or collection of
            Charges, including attorneys’ fees, collection fees, and costs
            whether or not litigation is commenced. You must report all
            accidents or incidents of theft and vandalism to us and the police
            as soon as you discover them. Where permitted by law, you authorize
            us to charge you for the actual cost of repair or replacement of
            lost or damaged items such as glass, mirrors and antenna, as part of
            the rental charges at the time of return.
          </Text>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={{ ...styles.heading2 }}>
              5. Prohibited Use of the Vehicle.{" "}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            Certain uses of the Vehicle and other actions by you or another
            driver or a passenger are prohibited uses
          </Text>
          <View>
            <Text
              style={{ ...styles.text, ...styles.section, ...styles.textBold }}
            >
              {" "}
              (“Prohibited Uses”). The following are Prohibited Uses of the
              Vehicle: (a) by anyone who (i) is not an Authorized Driver, or
              whose driving license is suspended in any jurisdiction; (ii) is
              under the influence of a prescription or non-prescription drug,
              controlled substance, or alcohol; or (iii) lacks experience
              operating a manual transmission if applicable; (b) in furtherance
              of any illegal purpose or under any circumstance that would
              constitute a felony or other violation of law (other than a minor
              traffic violation); (c) to carry persons or property for hire; (d)
              to push or tow anything, to teach anyone to drive, or to carry
              objects on the roof of the Vehicle; (e) in any race, speed test or
              contest; (f) to carry dangerous or hazardous items or illegal
              materiel; (g) outside the United States, Canada, or the geographic
              area described elsewhere in this Agreement; (h) when loaded beyond
              its capacity as determined by the manufacturer of the Vehicle; (i)
              on unpaved surfaces; (j) to transport more persons than the
              Vehicle has seat belts, or to carry persons outside the passenger
              compartment; (k) to transport children without approved child
              safety seats as required by law; (l) when the odometer has been
              tampered with or disconnected; (m) when the Vehicle’s fluid levels
              are low, or it is otherwise reasonable to expect you to know that
              further operation would damage the Vehicle; (n) with inadequately
              secured cargo; (o) after an accident with the Vehicle unless and
              until you summon the police to the accident scene; (p) to
              transport an animal (other than a service animal); (q) in or
              through any structure or underpass where there is insufficient
              clearance (width or height); (r) by anyone who is driving or
              operating the Vehicle while using a hand-held wireless
              communication device or other device that is capable of receiving
              or transmitting telephonic communications, electronic data, mail
              or text messages while not in a hands-free mode.; or (s) in a
              reckless manner or with willful or intentional disregard to the
              Vehicle or to third parties and their property. The following are
              also Prohibited Uses: failure to notify us and the police of an
              accident, theft, or vandalism involving the Vehicle; providing
              false, misleading or fraudulent information to us or withholding
              information that would have caused us not to rent the Vehicle; and
              smoking or vaping any substance in the Vehicle. PROHIBITED USE OF
              THE VEHICLE VIOLATES THIS AGREEMENT, AUTOMATICALLY TERMINATES YOUR
              RENTAL, AND VOIDS ALL LIABILITY AND OTHER INSURANCE COVERAGE (TO
              THE EXTENT PERMITTED BY LAW).
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={{ ...styles.heading2 }}>
              6. Collision Damage Waiver; Tire, Wheel and Windshield Damage
              Waiver.{" "}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            If we offer, and you purchase, CDW, we agree to waive our right to
            collect from you for all or a portion of Collision Damage as noted
            on the Rental Agreement. If we offer, and you purchase, TWWD, we
            agree to waive our right to collect from you for tire, wheel and
            windshield damage to the Vehicle.
          </Text>
          <View>
            <Text
              style={{ ...styles.text, ...styles.section, ...styles.textBold }}
            >
              {" "}
              CDW and TWWD are not insurance, are optional, and may duplicate
              coverage under your own insurance policy or credit card. CDW and
              TWWD do not apply to Optional Equipment. If you use the Vehicle
              for a Prohibited Use described above, any CDW or TWWD purchased by
              you will be invalidated, and we will not waive our right to hold
              you financially responsible for loss of or damage to the Vehicle.
              In addition, CDW or TWWD may be invalidated if the Vehicle is
              stolen and you fail to return the Vehicle keys or ignition devices
              that we gave you at the start of the rental. Notwithstanding the
              purchase or other availability of CDW, TWWD, or any other coverage
              that you may have, you agree to cooperate with us or our assignees
              in the investigation of any damage incident or claim of any size.
              Failure to do so may invalidate optional protection that you
              purchase, including CDW and TWWD.
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={{ ...styles.heading2 }}>7. Optional Equipment. </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            We offer certain Optional Equipment, including navigational systems
            and child safety seats, upon request and subject to availability for
            your use during the Rental Period at an additional charge.
          </Text>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            {" "}
            All Optional Equipment is rented AS IS and must be returned to us at
            the end of theRental Period in the same condition as when rented.
          </Text>
          <View>
            <Text style={{ ...styles.text, ...styles.section }}>
              {" "}
              If you rent a child safety seat, you must inspect and install the
              child seat into the Vehicle yourself. If you rent a navigational
              system, you should review the operational instructions before
              leaving the rental location.
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <View>
            <Text style={{ ...styles.heading2 }}>
              8. Insurance; Handling Accidents/Incidents.
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            You are responsible for all damage or loss you cause to others. You
            agree to provide automobile liability, collision and comprehensive
            insurance covering you, us, and the Vehicle. Where state law
            requires us to provide auto liability insurance, or if you have no
            auto liability insurance, we provide auto liability insurance (the
            “Policy”) that is excess to any other valid and collectible
            insurance whether primary, secondary, excess or contingent. The
            Policy provides bodily injury liability coverage and property damage
            liability coverage with limits no higher than minimum levels
            prescribed by the financial responsibility laws of the State whose
            laws apply to the loss. The Policy does not cover injury to you.
          </Text>
          <View>
            <Text
              style={{ ...styles.text, ...styles.section, ...styles.textBold }}
            >
              {" "}
              You and we reject PIP, medical payments, no-fault and uninsured
              and under-insured motorist coverage to the extent permitted by
              law.
            </Text>
          </View>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            To the extent such protection is imposed by operation of law, that
            protection will be for the minimum limits required by law. You must:
            (a) report all damage to us and all accidents to us and the police
            as soon as you discover them and complete our incident report form;
            and (b) provide us with a legible copy of any service of process,
            pleading, or notice of any kind related to an accident or other
            incident involving the Vehicle. Coverage under the Policy is void if
            you give the Vehicle to an unauthorized driver or otherwise
            materially breach this Agreement; or if you fail to cooperate in a
            loss investigation or to file a timely and accurate incident report.
          </Text>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={{ ...styles.heading2 }}>9. Payment; Charges.</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            {" "}
            You permit us to reserve against your payment card (“Reserve”) or
            take a cash deposit (“Deposit”) at the time of rental a reasonable
            amount in addition to the estimated charges. We may use the Reserve
            or Deposit to pay all Charges, but will not use the Reserve or
            Deposit to pay for damage to the Vehicle for which you may be
            responsible unless you agree separately to allow it after the amount
            of damage is determined. We will authorize the release of any excess
            Reserve or refund any excess Deposit after the completion of your
            rental. Your payment card issuer’s rules will apply to your account
            being credited for the excess and it may not be immediately released
            by your card issuer.
          </Text>
          <View>
            <Text style={{ ...styles.text, ...styles.section }}>
              {" "}
              You will pay us at or before conclusion of this rental or on
              demand all Charges, including: (a) time and mileage for the Rental
              Period, or a mileage charge based on our experience if the
              odometer is tampered with; (b) optional products and services you
              purchased including fees for additional drivers; (c) fuel and a
              refueling fee, if you return the Vehicle with less fuel than when
              rented (unless you purchase a prepaid fuel option); (d)
              government-imposed taxes, fees, and surcharges; (e) all expenses
              we incur locating and recovering the Vehicle if you fail to return
              it or if we repossess it under the terms of this Agreement; (f)
              all costs including pre- and post-judgment attorney fees we incur
              collecting payment from you or otherwise enforcing or defending
              our rights under this Agreement; (g) a 2% per month late payment
              fee, or the maximum amount allowed by law, on all amounts past
              due; (h) $50, or the maximum amount permitted by law, if you pay
              us with a check returned unpaid; (i) a reasonable fee to clean the
              Vehicle if returned substantially less clean than when rented or
              if the Vehicle contains evidence of smoking; (j) towing, storage
              charges, forfeitures, court costs, penalties, and all other costs
              we incur resulting from your use of the Vehicle; (k) a reasonable
              fee if you lose the keys or toll transponder to the Vehicle; (l)
              replacement cost of lost or damaged parts and supplies used in
              Optional Equipment; (m) a reasonable fee if a navigational system
              that you rented is lost, stolen or otherwise rendered unusable
              during the rental; and (n) a surcharge if you return the Vehicle
              to a location other than the location where you rented the Vehicle
              or if you return it after the date and time due
            </Text>
          </View>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            {" "}
            (“Due-In Date”).
          </Text>
          <View>
            <Text style={{ ...styles.text, ...styles.section }}>
              {" "}
              If you return the Vehicle earlier or later than the Due-In Date, a
              different or higher rate may apply, which may be substantially
              higher than the rates for the initially agreed rental period if a
              special or promotional rate applied to the initially agreed rental
              period.
            </Text>
            <Text
              style={{ ...styles.text, ...styles.section, ...styles.textBold }}
            >
              {" "}
              All Charges are subject to a final audit. If errors are found, you
              authorize us to correct the Charges with your payment card issuer.
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={{ ...styles.heading2 }}>
              10. Responsibility for Tolls, Traffic Violations, and Other
              Charges.
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            You are liable for all tolls
          </Text>
          <View>
            <Text
              style={{ ...styles.text, ...styles.section, ...styles.textBold }}
            >
              {" "}
              (“Tolls”)
            </Text>
          </View>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            and parking citations, photo enforcement fees, fines for toll
            evasion, and other fines, fees, and penalties (each a
          </Text>
          <View>
            <Text
              style={{ ...styles.text, ...styles.section, ...styles.textBold }}
            >
              {" "}
              “Violation”
            </Text>
            <Text style={{ ...styles.text, ...styles.section }}>
              ) assessed against you, us or the Vehicle during the Rental
              Period. If we are notified by charging authorities that we may be
              responsible for payment of a Toll or Violation, you agree that we
              or a processing firm
            </Text>
          </View>
          <View>
            <Text
              style={{ ...styles.text, ...styles.section, ...styles.textBold }}
            >
              {" "}
              (“Processor”)
            </Text>
            <Text style={{ ...styles.text, ...styles.section }}>
              {" "}
              may, in our sole discretion and without prior notice to you, pay
              the Toll or Violation plus applicable taxes on your behalf
              directly to the appropriate authority. If we or a Processor elect
              to pay a Toll or Violation, you may not be able to challenge the
              validity of the Toll or Violation before the charging authority.
              We or the Processor will charge you the face value of the Toll or
              Violation and any taxes, plus an administrative fee per Toll and
              Violation. If we or a Processor, in our sole discretion, elect to
              transfer liability for a Toll or Violation assessed against the
              Vehicle during the Rental Period to you personally, we or the
              Processor will charge you an administrative fee per Toll or
              Violation. You authorize us to release your rental and payment
              card information to the charging authorities and the Processor for
              processing and billing purposes. If we or the Processor pay a Toll
              or Violation, you authorize us and the Processor to charge all
              payments and administrative fees to the payment card you used in
              connection with this Agreement.
            </Text>
            <View>
              <Text
                style={{
                  ...styles.text,
                  ...styles.section,
                  ...styles.textBold,
                }}
              >
                {" "}
                Certain toll roads do not accept cash.
              </Text>
              <Text style={{ ...styles.text, ...styles.section }}>
                {" "}
                To avoid toll violations and associated fines, fees, and taxes
                (and our administrative fees), you must pay all tolls with a
                personal transponder that is accepted on the road; use only cash
                lanes and pay cash; plan a route to avoid tolls; or consult
                local authorities for other payment methods.
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={{ ...styles.heading2 }}>
              11. Personal Information; Communications.
            </Text>
          </View>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            You agree that we may disclose personally identifiable information
            about you to law enforcement agencies or to other third parties in
            connection with our enforcement of our rights under this Agreement
            and for other legitimate purposes. Questions regarding privacy
            should be directed to the location where you rented the Vehicle. To
            service your account or recover amounts you owe, you agree that we
            or our assignee may contact you by calling or sending text messages
            or emails to any email address or telephone number you provide us,
            including wireless telephone numbers, which could result in
            additional charges to you. You represent that you are either the
            owner or primary user of the number(s) and email address you
            provided. Methods of contact may include pre-recorded/artificial
            voice messages and/or use of an automatic dialing device. You may
            revoke your consent to this contact or to these types of
            communication at any time by contacting us in writing at the address
            on the Rental Agreement.
          </Text>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={{ ...styles.heading2 }}>12. Telematics Notice.</Text>
          </View>
        </View>
        <View>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            {" "}
            The Vehicle may be equipped with global positioning satellite (GPS)
            technology or another telematics system, and/or an event data
            recorder (EDR).
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            You acknowledge and authorize that your use of this Vehicle may be
            remotely monitored by us or on behalf of us through such systems to
            the extent permitted by law.
          </Text>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            {" "}
            This remote monitoring may include collection of Vehicle data, such
            as: location, odometer, oil life, fuel level, tire pressure, battery
            state of charge, diagnostic trouble codes, and other elements we may
            deem necessary. To the extent permitted by law, we may disable the
            Vehicle when we deem necessary, including if you breach this
            Agreement. These systems may use cellular communications, and you
            should have no expectation of privacy related to your use of this
            Vehicle.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            You agree to inform all drivers and passengers of the Vehicle of the
            terms of this section, and that you have authorized release of
            information collected by GPS or other telematics system or EDR.
          </Text>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            {" "}
            We are not responsible for the operability of any telematics
            navigational or other system included with the Vehicle.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            To the extent permitted by law, you agree to indemnify, defend and
            hold us harmless from any damage to persons or property caused by
            failure of the GPS or other telematics system or EDR to operate
            properly, or otherwise arising from the use of the GPS or other
            telematics system or EDR.
          </Text>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={{ ...styles.heading2 }}>13. Personal Property.</Text>
          </View>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            We are not responsible for loss of or damage to personal property
            that was left with us or carried in or on the Vehicle. If you fail
            to claim property left in the Vehicle for more than 30 days, we may
            dispose of that property in a manner we choose. To the extent
            permitted by law, You waive all claims all claims against us, our
            agents and employees for loss of or damage to the personal property
            of you or another person, which we received, handled, or stored, or
            which was left or carried in or on the Vehicle or in any service
            vehicle or in our offices, whether or not the loss of damage was
            caused by our negligence or was otherwise our responsibility.
          </Text>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            {" "}
            The Vehicle may be equipped with an infotainment system that permits
            you to pair your own mobile devices, and which may download your
            personal contacts, communications, location or other digital data.
            You should wipe all personal information from the Vehicle’s systems
            before returning it.
          </Text>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={{ ...styles.heading2 }}>14. Miscellaneous.</Text>
          </View>
        </View>
        <View>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            No term of this Agreement can be waived or modified except by a
            writing that we have signed. This Agreement constitutes the entire
            agreement between you and us. All prior representations and
            agreements between you and us regarding this rental are void. A
            waiver by us of any breach of this Agreement is not a waiver of any
            additional breach or waiver of the performance of your obligations
            under this Agreement. Our acceptance of payment from you or our
            failure, refusal or neglect to exercise any of our rights under this
            Agreement does not constitute a waiver of any other provision of
            this Agreement.
          </Text>
          <Text
            style={{ ...styles.text, ...styles.section, ...styles.textBold }}
          >
            {" "}
            To the extent permitted by law: (a) you waive all recourse against
            us for any criminal reports or prosecutions that we take against you
            that arise out of your breach of this Agreement; and (b) you release
            us from all liability for consequential, special or punitive damages
            in connection with this rental or the reservation of a vehicle.
          </Text>
          <Text style={{ ...styles.text, ...styles.section }}>
            {" "}
            If any provision of this Agreement is deemed void or unenforceable,
            the remaining provisions are valid and enforceable.
          </Text>
        </View>
        <Text style={{ ...styles.text, ...styles.section, ...styles.textBold }}>
          {" "}
          ARRC Generic 09.2018 (143715)
        </Text>

        <View style={styles.section}>
          <Text style={{ ...styles.heading2 }}>Note</Text>
        </View>
        <Text style={{ ...styles.text, ...styles.textBold }}>
          Missing Details in Rental Agreement
        </Text>

        <View style={styles.section}>
          <Text style={{ ...styles.text, ...styles.section }}>
            Please be advised that the following details are currently missing
            from the rental agreement: Driver's License Number, Date of Birth
            (DOB), and Expiry Date of License. These details are essential for
            processing insurance claims in the event of an accident.
          </Text>
          <Text style={{ ...styles.text}}>
            These missing details can be verified and completed using the
            booking details available in the owner's Stripe account for the
            respective customer. If this information is not available through
            Stripe, the customer will be required to provide these details upon
            the owner's request to ensure the validity of the rental agreement
            and insurance coverage.
          </Text>
        </View>

     
         
    
        <View style={styles.section}>
          <Text style={styles.heading1}>Signature</Text>
        </View>
        <View style={styles.section}>
          <Text
            style={{
              ...styles.heading1,
              ...{ fontFamily: signatureStylesText[selectedSignatureIndex] },
            }}
          >
            {stripeVerification?.person_details.first_name}{" "}
            {stripeVerification?.person_details.last_name}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const DownloadLink: React.FC = () => {
  const {
    selectedBonzahProduct,
    stripeVerification,
    selectedSignatureIndex,
    axleInsurance,
  } = useApp();
  return (
    <div>
      <PDFDownloadLink
        document={
          <RentalAgreement
            selectedSignatureIndex={selectedSignatureIndex}
            stripeVerification={stripeVerification!}
            selectedBonzahProduct={selectedBonzahProduct}
            axleInsurance={axleInsurance}
          />
        }
        fileName="rental_agreement.pdf"
        className=" cursor-pointer"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Rental Agreement"
        }
      </PDFDownloadLink>
    </div>
  );
};

export default RentalAgreement;
